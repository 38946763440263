import { useMemo, useState } from 'react';
import { Control, DeepRequired, FieldErrorsImpl, FieldValues } from 'react-hook-form';
import { debounce } from 'lodash';

import { Alert, Box, Button, Grid, InputAdornment, Typography } from '@mui/material';
import { BillingDetails } from '@one/api-models/lib/BillingDetails';
import { Money } from '@one/api-models/lib/Money';
import { PaymentMethod } from '@one/api-models/lib/Sales/Payment/PaymentMethod/PaymentMethod';

import ControlledTextField from 'common/inputs/defaultFields/ControlledTextField';
import { SectionTitle } from 'common/SectionTitle';
import { AddPaymentMethodDialog } from 'core/payment/AddPaymentMethodDialog';
import { mapCurrency } from 'core/payment/utils/utils';
import { useFormat } from 'hooks/useFormat';
import {
  minAdHocAmount,
  PaymentMethodOption,
  UpdatePaymentPlanForm,
} from 'modules/paymentPlans/components/EditPaymentPlanDialog';
import { formatAddress } from 'utils/address';

import { PaymentPlanControlledCardSelector } from './PaymentPlanControlledCardSelector';

export interface Props {
  control: Control<UpdatePaymentPlanForm, object>;
  errors: FieldErrorsImpl<DeepRequired<UpdatePaymentPlanForm>>;
  isInstallmentPlan?: boolean;
  isPaymentPlanEditable?: boolean;
  adhocPaymentAmount: string | undefined;
  balance?: Money;
  selectedPaymentMethod: string | undefined;
  selectedPaymentMethodBillingDetails?: BillingDetails;
  paymentMethodListOptions?: PaymentMethodOption[];
  isFetchingPaymentMethodList?: boolean;
  isLoadingCalculatePaymentPlan?: boolean;
  testId: string;
  memberId: string;
  defaultBillingDetails: BillingDetails;
  handleCalculatePaymentPlan: () => void;
  handleChangePaymentMethod: (paymentMethodReference: string | undefined) => void;
  handleAddPaymentMethod: (paymentMethod: PaymentMethod) => void;
  handleOpenBillingDetailsDialog: () => void;
}

export const PaymentPlanPaymentsSection = (props: Props) => {
  const {
    control,
    errors,
    isInstallmentPlan,
    isPaymentPlanEditable,
    balance,
    selectedPaymentMethod,
    selectedPaymentMethodBillingDetails,
    adhocPaymentAmount,
    paymentMethodListOptions,
    isFetchingPaymentMethodList,
    isLoadingCalculatePaymentPlan,
    testId,
    memberId,
    defaultBillingDetails,
    handleCalculatePaymentPlan,
    handleChangePaymentMethod,
    handleAddPaymentMethod,
    handleOpenBillingDetailsDialog,
  } = props;

  const { formatCurrency: formatCurrencyFunc } = useFormat();
  const formatCurrency = (amount: number | null | undefined, currency: string | undefined) => {
    return formatCurrencyFunc(amount, currency, 2);
  };

  const [addPaymentModalOpen, setAddPaymentModalOpen] = useState<boolean>(false);

  const handleAddPaymentMethodModalOpen = () => {
    setAddPaymentModalOpen(true);
  };

  const handleAddPaymentMethodModalClose = () => {
    setAddPaymentModalOpen(false);
  };

  const debouncedHandleAdhocPaymentAmountChange = debounce(() => {
    handleCalculatePaymentPlan();
  }, 800);

  const isAdhocPaymentAmountValid = useMemo(() => {
    if (
      adhocPaymentAmount === '' ||
      (adhocPaymentAmount &&
        balance?.amount &&
        parseFloat(adhocPaymentAmount) >= minAdHocAmount &&
        parseFloat(adhocPaymentAmount) <= balance?.amount)
    ) {
      return true;
    }

    return false;
  }, [balance, adhocPaymentAmount]);
  const isAdhocPaymentAmountValidMessage = `Then entered amount must be between ${formatCurrency(
    minAdHocAmount,
    balance?.currency,
  )} and ${formatCurrency(balance?.amount, balance?.currency)}`;

  const isPaymentMethodValid = useMemo(() => {
    if (adhocPaymentAmount === '' || (adhocPaymentAmount && !!selectedPaymentMethod)) {
      return true;
    }

    return false;
  }, [selectedPaymentMethod, adhocPaymentAmount]);
  const isPaymentMethodValidMessage = 'Payment method is required';

  return (
    <Box sx={{ mb: 6 }}>
      <SectionTitle title="Payments" variant="h4" />

      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        Add Additional Payments to Payment Plan:
      </Typography>

      <Grid container spacing={2} rowSpacing={2}>
        <Grid item xs={12} sm={3} md={4} lg={3}>
          <ControlledTextField
            name="adhocPaymentAmount"
            control={control as unknown as Control<FieldValues, object>}
            fullWidth
            label="Amount"
            type="number"
            error={errors.adhocPaymentAmount?.message != null || !isAdhocPaymentAmountValid}
            helperText={
              errors.adhocPaymentAmount?.message || (!isAdhocPaymentAmountValid && isAdhocPaymentAmountValidMessage)
            }
            InputProps={{
              startAdornment: <InputAdornment position="start">{mapCurrency(balance?.currency)}</InputAdornment>,
              inputProps: {
                min: minAdHocAmount,
                max: balance?.amount,
                step: 0.01,
              },
            }}
            onChange={() => {
              debouncedHandleAdhocPaymentAmountChange();
            }}
            disabled={!isInstallmentPlan || !isPaymentPlanEditable || isLoadingCalculatePaymentPlan}
            testId={`${testId}Amount`}
          />
        </Grid>
        <Grid item container xs={12} sm={8} md={8} lg={5}>
          <Grid item xs={12} md={12}>
            <PaymentPlanControlledCardSelector
              isLoading={isFetchingPaymentMethodList}
              control={control as unknown as Control<FieldValues, object>}
              name="adhocPaymentMethodReference"
              label="Payment Method"
              options={paymentMethodListOptions}
              error={errors.paymentMethodReference != null || !isPaymentMethodValid}
              helperText={
                errors.paymentMethodReference?.message || (!isPaymentMethodValid && isPaymentMethodValidMessage)
              }
              requiredMessage="Payment method is required"
              disableClearable
              popperWidth="300px"
              onChange={(value) => {
                handleChangePaymentMethod(value);
              }}
              disabled={!isInstallmentPlan || !isPaymentPlanEditable}
              getOptionDisabled={(option) => {
                const currentDate = new Date();
                return (
                  option.expirationYear < currentDate.getFullYear() ||
                  (option.expirationYear === currentDate.getFullYear() &&
                    option.expirationMonth <= currentDate.getMonth())
                );
              }}
              testId={`${testId}PaymentMethod`}
            />
            <Button
              onClick={handleAddPaymentMethodModalOpen}
              variant="text"
              disableRipple
              size="small"
              className="noXPadding"
              tabIndex={-1}
              disabled={!isInstallmentPlan || !isPaymentPlanEditable}
              data-testid={`${testId}AddPaymentMethodButton`}
            >
              Add payment method
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Billing to:</Typography>
          <Box display="flex" flexDirection="column">
            <Typography variant="body1">
              {`${selectedPaymentMethodBillingDetails?.firstName || ''} ${
                selectedPaymentMethodBillingDetails?.lastName || ''
              }`}
            </Typography>
            <Typography variant="caption">{selectedPaymentMethodBillingDetails?.email || ''}</Typography>
            <Typography variant="caption">
              {formatAddress(selectedPaymentMethodBillingDetails?.billingAddress)}
            </Typography>
            <Button
              onClick={handleOpenBillingDetailsDialog}
              disabled={!isInstallmentPlan || !isPaymentPlanEditable}
              variant="text"
              data-testid={`${testId}ChangeBillingDetailsButton`}
              sx={{ pl: 0, maxWidth: '156px', textTransform: 'none' }}
            >
              Change Billing Details
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Alert severity="info">The card will be charged only when you press Update Payment Plan</Alert>
        </Grid>
      </Grid>

      {addPaymentModalOpen && (
        <AddPaymentMethodDialog
          memberId={memberId}
          open={addPaymentModalOpen}
          billingDetails={defaultBillingDetails}
          callback={handleAddPaymentMethod}
          setAddPaymentModalOpen={setAddPaymentModalOpen}
          onClose={handleAddPaymentMethodModalClose}
          testId={`${testId}Dialog`}
        />
      )}
    </Box>
  );
};
