import { useRef } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/styles';
import { BillingDetails } from '@one/api-models/lib/BillingDetails';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from 'styled';

import { BillingDetailsForm } from './BillingDetailsForm';

interface BillingDetailsDialogProps {
  open: boolean;
  validateOnOpen: boolean;
  billingDetails?: BillingDetails;
  handleClose: () => void;
  handleBillingDetailsChange: (data: BillingDetails) => void;
  testId: string;
}

export const BillingDetailsDialog = ({
  open,
  validateOnOpen,
  handleClose,
  billingDetails,
  handleBillingDetailsChange,
  testId,
}: BillingDetailsDialogProps) => {
  const formRef = useRef<HTMLFormElement>(null);
  const theme = useTheme();

  const handleBillingDataChange = (data: BillingDetails) => {
    handleBillingDetailsChange(data);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      sx={{
        '.MuiDialog-paper': {
          width: '100%',
          maxWidth: '496px',
        },
      }}
    >
      <DialogTitle>
        Update Billing Details
        <IconButton onClick={handleClose} size="small" disableRipple data-testid={`${testId}CloseButton`}>
          <CloseIcon sx={{ color: theme.customPalette.icons.light }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <BillingDetailsForm
          validateOnOpen={validateOnOpen}
          formRef={formRef}
          testId={testId}
          initialDetails={billingDetails}
          onBillingDataSubmit={handleBillingDataChange}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} data-testid={`${testId}CancelButton`}>
          Cancel
        </Button>
        <Button
          variant="contained"
          size="medium"
          onClick={() => {
            formRef?.current?.requestSubmit();
          }}
          data-testid={`${testId}UpdateButton`}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};
