import { useIsMutating, useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Paper } from '@mui/material';
import { Box } from '@mui/system';
import { PromoCodeDefinition } from '@one/api-models/lib/Campaign/PromoCodeDefinition/PromoCodeDefinition';
import { CreateRequest } from '@one/api-models/lib/Campaign/PromoCodeDefinition/Request/CreateRequest';
import { CreateResponse } from '@one/api-models/lib/Campaign/PromoCodeDefinition/Response/CreateResponse';

import { selectActiveBrand } from 'store/slices/applicationDataSlice';

import { ApiError } from 'apiAccess/api-client';
import { Loading } from 'common';
import { PageHeader } from 'common/layout/components/PageHeader';
import { useApiHelpers } from 'hooks/useApiHelpers';
import { useToastMessage } from 'hooks/useToastMessage';

import { PromoCodeDefinitionForm } from './components/PromoCodeDefinitionForm';

export const AddPromoCodeDefinitionView = () => {
  const createIsLoading = useIsMutating({ mutationKey: 'createPromoCodeDefMutation' }) > 0;
  const navigate = useNavigate();
  const { api } = useApiHelpers();
  const { apiErrorHandler, showMessage } = useToastMessage();
  const activeBrand = useSelector(selectActiveBrand);
  const testIdPrefix = 'NewPromoCodeDefinition';

  const createPromoCodeDefMutation = useMutation<CreateResponse, ApiError, CreateRequest, unknown>(
    (request) => api.promoCodeDefinition.create(request),
    {
      mutationKey: 'createPromoCodeDefMutation',
      onSuccess: (value: CreateResponse) => {
        if (value) {
          showMessage('Promo Code Definition created successfully.', 'success');
          navigate(`/marketing/promo-codes/${value.id}`);
        }
      },
      onError: apiErrorHandler,
    },
  );

  const handleCreatePromoCodeDefSubmit = (data: PromoCodeDefinition) => {
    createPromoCodeDefMutation.mutateAsync({
      promoCodeDefinition: data,
      brandKey: activeBrand?.key ?? '',
    });
  };

  return (
    <Box>
      {createIsLoading && <Loading message="Saving your changes..." />}
      <PageHeader title="New Promo Code Definition" testId={testIdPrefix} />
      <Paper sx={{ p: 3 }}>
        <PromoCodeDefinitionForm handleSavePromoCodeDefinition={handleCreatePromoCodeDefSubmit} testId={testIdPrefix} />
      </Paper>
    </Box>
  );
};
