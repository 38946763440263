import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { BillingDetails } from '@one/api-models/lib/BillingDetails';
import { PaymentMethod } from '@one/api-models/lib/Sales/Payment/PaymentMethod/PaymentMethod';

import { selectIsLoadingCreatePaymentMethod, setCreatePaymentMethodError } from 'store/slices/paymentDataSlice';

import { PaymentProvider } from 'common/payment/PaymentProvider';
import { AddPaymentMethodComponentRef } from 'models/AddPaymentMethodComponentRef';

interface AddPaymentMethodDialogProps {
  open: boolean;
  memberId: string;
  testId: string;
  onClose: () => void;
  callback: (paymentMethod: PaymentMethod) => void;
  setAddPaymentModalOpen: (value: boolean) => void;
  billingDetails: BillingDetails;
}

export const AddPaymentMethodDialog = ({
  open,
  memberId,
  testId,
  onClose,
  callback,
  setAddPaymentModalOpen,
  billingDetails,
}: AddPaymentMethodDialogProps) => {
  const dispatch = useDispatch();
  const addPaymentMethodFormRef = useRef<AddPaymentMethodComponentRef>(null);

  const isLoadingCreatePaymentMethod = useSelector(selectIsLoadingCreatePaymentMethod);

  const resetCreatePaymentMethodError = () => {
    dispatch(setCreatePaymentMethodError(undefined));
  };

  const closeModal = () => {
    resetCreatePaymentMethodError();
    onClose();
  };

  return (
    <Dialog open={open} onClose={closeModal} maxWidth="xs" fullWidth>
      <DialogTitle
        variant="h6"
        sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', boxShadow: 'none' }}
      >
        <Box>Add payment method</Box>
        <IconButton
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          size="small"
          disableRipple
          disabled={isLoadingCreatePaymentMethod}
          data-testId={`${testId}CloseButton`}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Box sx={{ position: 'relative' }}>
          <PaymentProvider
            type="adhocPayment"
            memberId={memberId}
            testId={testId}
            resetCreatePaymentMethodError={resetCreatePaymentMethodError}
            callback={callback}
            setAddPaymentModalOpen={setAddPaymentModalOpen}
            billingDetails={billingDetails}
            componentRef={addPaymentMethodFormRef}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          size="small"
          disableRipple
          onClick={closeModal}
          disabled={isLoadingCreatePaymentMethod}
          data-testid={`${testId}CancelButton`}
        >
          Cancel
        </Button>
        <LoadingButton
          disabled={isLoadingCreatePaymentMethod}
          disableRipple
          variant="contained"
          type="submit"
          onClick={() => addPaymentMethodFormRef.current?.submitAddPaymentMethod()}
          size="small"
          loading={isLoadingCreatePaymentMethod}
          data-testid={`${testId}SaveButton`}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
