import { RefObject, useEffect, useRef, useState } from 'react';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import { Box, Grid } from '@mui/material';
import { BillingDetails } from '@one/api-models/lib/BillingDetails';
import { PaymentMethod } from '@one/api-models/lib/Sales/Payment/PaymentMethod/PaymentMethod';

import { setCreatePaymentMethodError } from 'store/slices/paymentDataSlice';
import {
  PaymentOptions,
  selectIsCompleteWithNoPayment,
  selectPurchaseData,
  selectSelectedPaymentOption,
  setInvoiceBillingDetails,
} from 'store/slices/salesOrderDataSlice';

import { Loading } from 'common';
import { BillingDetailsComponentRef, BillingDetailsForm } from 'common/billingDetails/BillingDetailsForm';
import { PaymentProvider } from 'common/payment/PaymentProvider';
import { isBillingDetailsRequiredByPaymentProviderValid } from 'core/payment/utils/utils';
import { useProgramSale } from 'hooks/useProgramSale';
import { useRetrievePaymentMethodList } from 'hooks/useRetrievePayment';
import { AddPaymentMethodComponentRef } from 'models/AddPaymentMethodComponentRef';
import { PaymentMethodOption } from 'modules/paymentPlans/components/EditPaymentPlanDialog';
import { CardSelector } from 'modules/salesOrders/components/CardSelector';
import { Button, SectionTitle, Typography } from 'styled';
import { formatAddress } from 'utils/address';
import { mapGatewayIdentifierLabel } from 'utils/payment';

import { PaymentForm } from './payment/PaymentForm';

interface ProgramSalesPaymentForm {
  paymentMethodReference: string;
}
interface SalesOrderPaymentProps {
  formRef: RefObject<HTMLFormElement>;
  memberId: string;
  testIdPrefix: string;
  defaultBillingDetails?: BillingDetails;
}

export const SalesOrderPayment = ({
  formRef,
  memberId,
  testIdPrefix,
  defaultBillingDetails,
}: SalesOrderPaymentProps) => {
  const dispatch = useDispatch();
  const billingDetailsFormRef = useRef<BillingDetailsComponentRef>(null);
  const addPaymentMethodFormRef = useRef<AddPaymentMethodComponentRef>(null);
  const [addPaymentMethodOpen, setAddPaymentMethodOpen] = useState<boolean>(false);
  const [billingDetailsOpen, setBillingDetailsOpen] = useState<boolean>(false);
  const selectedPaymentOption = useSelector(selectSelectedPaymentOption);
  const [billingDetails, setBillingDetails] = useState<BillingDetails | undefined>(defaultBillingDetails);
  const isCompleteWithNoPayment = useSelector(selectIsCompleteWithNoPayment);

  const showPaymentElements = !isCompleteWithNoPayment && selectedPaymentOption !== PaymentOptions.Reservation;
  const { processCreateOrder, isLoading: isProcessingOrder } = useProgramSale();
  const purchaseData = useSelector(selectPurchaseData);

  // Ref required for input attached events
  const selectedPaymentOptionRef = useRef(selectedPaymentOption);

  const [selectedPaymentMethodReference, setSelectedPaymentMethodReference] = useState<PaymentMethodOption | undefined>(
    undefined,
  );
  const [additionalPaymentMethodListOptions, setAdditionalPaymentMethodListOptions] = useState<PaymentMethodOption[]>(
    [],
  );
  const validatePaymentPlanForm = useRef(() => {
    return false;
  });

  const validationSchema = yup.object().shape({
    paymentMethodReference: yup.string().when('addPaymentMethodOpen', {
      is: true,
      then: yup.string().required('Payment method is required'),
      otherwise: yup.string().notRequired(),
    }),
  });

  const defaultValues: ProgramSalesPaymentForm = {
    paymentMethodReference: '',
  };

  const {
    getValues,
    setValue,
    control,
    formState: { errors },
    trigger,
  } = useForm<ProgramSalesPaymentForm>({ defaultValues, resolver: yupResolver(validationSchema) });

  const { isFetching: isFetchingAdditionalPaymentMethodList, data: additionalPaymentMethodListData } =
    useRetrievePaymentMethodList(memberId, true);

  useEffect(() => {
    dispatch(setInvoiceBillingDetails(billingDetails));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingDetails]);

  useEffect(() => {
    if (additionalPaymentMethodListData && additionalPaymentMethodListData?.paymentMethods?.length > 0) {
      const additionalFormattedPaymentMethods = additionalPaymentMethodListData?.paymentMethods?.map(
        (i: PaymentMethod) => ({
          code: i.paymentMethodId.toString(),
          label: `${mapGatewayIdentifierLabel(i.gatewayIdentifier)} \n **** **** **** ${i.description}`,
          isDefaultPaymentMethod: false,
          expirationMonth: i.expirationMonth,
          expirationYear: i.expirationYear,
          billingDetails: i.billingDetails,
        }),
      );
      setAddPaymentMethodOpen(false);
      setAdditionalPaymentMethodListOptions(additionalFormattedPaymentMethods);
      const defaultPaymentMethod = additionalFormattedPaymentMethods[0];

      if (defaultPaymentMethod && !selectedPaymentMethodReference) {
        setValue('paymentMethodReference', defaultPaymentMethod?.code);
        setSelectedPaymentMethodReference(defaultPaymentMethod);
        const currentBillingDetails: BillingDetails = defaultPaymentMethod.billingDetails ?? defaultBillingDetails;
        setBillingDetails(currentBillingDetails);
        setBillingDetailsOpen(isBillingDetailsRequiredByPaymentProviderValid(currentBillingDetails));
      }
    } else {
      setAddPaymentMethodOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalPaymentMethodListData, defaultBillingDetails]);

  useEffect(() => {
    selectedPaymentOptionRef.current = selectedPaymentOption;
  }, [selectedPaymentOption]);

  const handleChangePaymentMethod = (paymentMethodReference: string | undefined) => {
    const selectedPaymentMethod = additionalPaymentMethodListOptions.find(
      (i: PaymentMethodOption) => i.code === paymentMethodReference,
    );
    if (selectedPaymentMethod) {
      const currentBillingDetails = selectedPaymentMethod.billingDetails ?? defaultBillingDetails;
      setBillingDetails(currentBillingDetails);
      setBillingDetailsOpen(isBillingDetailsRequiredByPaymentProviderValid(currentBillingDetails));
      setSelectedPaymentMethodReference(selectedPaymentMethod);
    }
  };

  const resetCreatePaymentMethodError = () => {
    dispatch(setCreatePaymentMethodError(undefined));
  };

  const handleAddPaymentMethodAndProcessOrder = (paymentMethod: PaymentMethod) => {
    setValue('paymentMethodReference', paymentMethod.paymentMethodId.toString());
    setAdditionalPaymentMethodListOptions([
      ...additionalPaymentMethodListOptions,
      {
        code: paymentMethod.paymentMethodId.toString(),
        label: `${mapGatewayIdentifierLabel(paymentMethod.gatewayIdentifier)} \n **** **** **** ${
          paymentMethod.description
        }`,
        isDefaultPaymentMethod: false,
        expirationMonth: paymentMethod.expirationMonth,
        expirationYear: paymentMethod.expirationYear,
        billingDetails: paymentMethod.billingDetails,
      },
    ]);
    setAddPaymentMethodOpen(false);

    const currentBillingDetails = paymentMethod ? paymentMethod?.billingDetails : defaultBillingDetails;
    setBillingDetails(currentBillingDetails);
    setBillingDetailsOpen(isBillingDetailsRequiredByPaymentProviderValid(currentBillingDetails));
    processCreateOrder(paymentMethod.paymentMethodId, currentBillingDetails);
  };

  const submitForm = async () => {
    let isAdditionalFormValid = true;
    if (
      (selectedPaymentOptionRef.current === PaymentOptions.PaymentPlan ||
        selectedPaymentOptionRef.current === PaymentOptions.SplitPayment) &&
      !!validatePaymentPlanForm &&
      !!validatePaymentPlanForm.current
    ) {
      isAdditionalFormValid = await validatePaymentPlanForm.current();
    }

    if (addPaymentMethodFormRef.current && addPaymentMethodOpen) {
      const isAddPaymentMethodValid = await addPaymentMethodFormRef.current.validate();
      const isValid = await trigger();
      if (!isAddPaymentMethodValid || !isValid || !isAdditionalFormValid) {
        return;
      }
      await addPaymentMethodFormRef.current.submitAddPaymentMethod();
    } else {
      if (billingDetailsOpen) {
        const isBillingDetailsValid = await billingDetailsFormRef?.current?.validate();
        const isValid = await trigger();
        if (!isBillingDetailsValid || !isValid) {
          return;
        }
      }
      if (isAdditionalFormValid) {
        const currentBillingDetails = await billingDetailsFormRef?.current?.getBillingDetails();
        processCreateOrder(parseInt(getValues('paymentMethodReference')), currentBillingDetails);
      }
    }
  };

  const handleBillingDataChanged = (billingDetails: BillingDetails) => {
    dispatch(setInvoiceBillingDetails(billingDetails));
  };

  return (
    <Box sx={{ mt: 7 }}>
      {isProcessingOrder && <Loading message="Please wait while we process your order..." />}

      <Typography variant="h5">Payment</Typography>
      {!!purchaseData?.paymentPlanConfig && (
        <Grid item xs={12} key="pf" mt={2}>
          <PaymentForm validatePaymentForm={validatePaymentPlanForm} testId={testIdPrefix} />
        </Grid>
      )}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submitForm();
        }}
        ref={formRef}
      >
        {showPaymentElements && (
          <Box sx={{ mt: 2 }}>
            <CardSelector
              isLoading={isFetchingAdditionalPaymentMethodList}
              control={control as unknown as Control<FieldValues, object>}
              name="paymentMethodReference"
              label="Payment Method"
              options={additionalPaymentMethodListOptions}
              error={errors.paymentMethodReference != null}
              helperText={errors.paymentMethodReference?.message}
              requiredMessage="Payment method is required"
              popperWidth="300px"
              disabled={addPaymentMethodOpen}
              onChange={(value) => {
                handleChangePaymentMethod(value);
              }}
              testId={`${testIdPrefix}PaymentMethod`}
            />
          </Box>
        )}

        {addPaymentMethodOpen && showPaymentElements ? (
          <Box sx={{ mt: 3 }}>
            <PaymentProvider
              type="adhocPayment"
              memberId={memberId}
              testId={`${testIdPrefix}AddPaymentMethod`}
              onClose={() => setAddPaymentMethodOpen(false)}
              resetCreatePaymentMethodError={resetCreatePaymentMethodError}
              callback={handleAddPaymentMethodAndProcessOrder}
              setAddPaymentModalOpen={setAddPaymentMethodOpen}
              billingDetails={billingDetails}
              onBillingDataChanged={handleBillingDataChanged}
              componentRef={addPaymentMethodFormRef}
            />
          </Box>
        ) : (
          <>
            {showPaymentElements && (
              <Button
                onClick={() => {
                  setBillingDetails(defaultBillingDetails);
                  setAddPaymentMethodOpen(true);
                  setSelectedPaymentMethodReference(undefined);
                }}
                variant="text"
                startIcon={<AddIcon />}
                data-testid={`${testIdPrefix}AddPaymentMethodButton`}
                sx={{ mt: 0.5 }}
              >
                Add New
              </Button>
            )}
            <Box my={3}>
              <SectionTitle>Billing to</SectionTitle>
              {!billingDetailsOpen ? (
                <Box display="flex" flexDirection="column">
                  <Typography variant="body1">
                    {`${billingDetails?.firstName || ''} ${billingDetails?.lastName || ''}`}
                  </Typography>
                  <Typography variant="caption">{billingDetails?.email || ''}</Typography>
                  <Typography variant="caption">{formatAddress(billingDetails?.billingAddress)}</Typography>
                  <Button
                    onClick={() => setBillingDetailsOpen(true)}
                    variant="text"
                    data-testid={`${testIdPrefix}ChangeBillingDetailsButton`}
                    sx={{ pl: 0, maxWidth: '156px' }}
                  >
                    Change Billing Details
                  </Button>
                </Box>
              ) : (
                <BillingDetailsForm
                  initialDetails={billingDetails}
                  componentRef={billingDetailsFormRef}
                  onBillingDataChanged={handleBillingDataChanged}
                  testId={`${testIdPrefix}BillingDetailsForm`}
                />
              )}
            </Box>
          </>
        )}
      </form>
    </Box>
  );
};
