import { Box, Skeleton } from '@mui/material';

import { Loading } from 'common/Loading';

interface PaymentSkeletonProps {
  isLoadingConfiguration?: boolean;
}

export const PaymentSkeleton = ({ isLoadingConfiguration }: PaymentSkeletonProps) => {
  return (
    <>
      <Box>
        <Skeleton variant="rectangular" height={120} sx={{ mt: 1 }} />
        <Skeleton variant="rectangular" height={300} sx={{ mt: 3 }} />
      </Box>

      {isLoadingConfiguration && <Loading message="Loading..." />}
    </>
  );
};
