import { Fragment, useState } from 'react';
import { differenceInDays } from 'date-fns';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  alpha,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import { PurchaseSummary } from '@one/api-models/lib/Admin/Sales/PurchaseSummary';
import { AirOrderItemSummary } from '@one/api-models/lib/Admin/Sales/Travel/Air/AirOrderItemSummary';
import { Baggage } from '@one/api-models/lib/Admin/Sales/Travel/Air/Baggage';
import { CabinClass } from '@one/api-models/lib/Admin/Sales/Travel/Air/CabinClass';
import { FareRuleType } from '@one/api-models/lib/Admin/Sales/Travel/Air/FareRuleType';
import { Itinerary } from '@one/api-models/lib/Admin/Sales/Travel/Air/Itinerary';
import { SegmentEndPoint } from '@one/api-models/lib/Admin/Sales/Travel/Air/SegmentEndPoint';
import { Traveler } from '@one/api-models/lib/Admin/Sales/Travel/Air/Traveler';
import { FareRule } from '@one/api-models/lib/Sales/Travel/Air/FareRule';
import { TermsAndConditions } from '@one/api-models/lib/Sales/Travel/Air/TermsAndConditions';

import { DataDisplay } from 'common';
import { SectionTitle } from 'common/SectionTitle';
import { useFormat } from 'hooks/useFormat';
import { OrderItemPricingSummary } from 'modules/salesOrders/components/orderItemDetails/OrderItemPricingSummary';

import { airlinesFrequentFlyer } from '../../constants/frequentFlyerProgramList';
import { AGE_BAND_TYPE, AIR_TRIP_TYPE } from '../../constants/orderValues';

interface Props {
  orderItem: AirOrderItemSummary;
  purchaseSummary?: PurchaseSummary;
}
interface TermsProps {
  terms: TermsAndConditions[];
  itineraries: Itinerary[];
}

interface TermsContent {
  label: string;
  content: string;
  segmentIds: string[];
}

interface TermsContentProps {
  content: TermsContent;
}
interface SegmentCodeByIdsProps {
  segmentsIds: string[];
  itinerary: Itinerary;
}

interface ItineraryBaggage {
  bag: Baggage;
  segments: string[];
}

interface SegmentProps {
  content: TermsContent;
  itineraries: Itinerary[];
}
interface FareRulesProps {
  fareRules: FareRule[];
}
interface FareRuleProps {
  fareRule: FareRule;
}

export const AirOrderItemDetails = ({ orderItem, purchaseSummary }: Props) => {
  const { productSummary } = orderItem;
  const [showTermsDetails, setShowTermsDetails] = useState(false);
  const { formatDate: _formatDate } = useFormat();
  const formatDate = (date: string | Date | null | undefined, format = 'dd MMM yyyy', isLocalTime = true): string => {
    if (!date) return 'N/A';
    return _formatDate(new Date(date!), isLocalTime, format) ?? 'N/A';
  };

  return (
    <>
      <SectionTitle title="Overview" variant="h6" />
      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={{ xs: 1, md: 2, lg: 4 }}>
        <DataDisplay label="Name" value={productSummary.name} />
        <Divider orientation="vertical" flexItem />
        <DataDisplay label="Trip Type" value={AIR_TRIP_TYPE[productSummary.tripType]} />
      </Box>
      <SectionTitle title="Flight details" variant="h6" sx={{ mt: 2 }} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          height: '100%',
          mb: 2,
        }}
      >
        {orderItem.productSummary.itineraries?.map((itinerary, index) => (
          <Box sx={{ mb: index !== productSummary.itineraries.length - 1 ? 1 : 0 }} key={index}>
            <ItineraryOverview itinerary={itinerary} />
            {index !== orderItem.productSummary.itineraries.length - 1 && (
              <Divider sx={{ display: { xs: 'block', md: 'none' }, mt: 1 }} />
            )}
          </Box>
        ))}
      </Box>

      <Divider sx={{ my: 2 }} />

      <Grid container spacing={{ xs: 1, sm: 2 }}>
        {orderItem.travelers.map((traveler: Traveler, index: number) => (
          <Grid item xs={12} sm={6} md={4} key={`traveler_${index}`}>
            <SectionTitle title={`Traveler ${index + 1} (${AGE_BAND_TYPE[traveler.ageBandType]})`} variant="h6" />
            <Box display="flex" flexDirection="column" gap={1} sx={{ wordBreak: 'break-word' }}>
              <DataDisplay label="Name" value={`${traveler.firstName} ${traveler.lastName}`} />

              {traveler.dateOfBirth && (
                <DataDisplay label="Date of birth" value={formatDate(traveler.dateOfBirth, undefined, false)} />
              )}
              {traveler.email && <DataDisplay label="Email" value={traveler.email} />}
              {traveler.phone && <DataDisplay label="Phone" value={traveler.phone} />}
              {!!traveler.hasDeclinedToProvideContactInfo && (
                <Typography
                  fontSize="0.875rem"
                  fontWeight={400}
                  maxWidth="250px"
                  sx={{ opacity: 1, color: (theme) => alpha(theme.palette.common.black, 0.7) }}
                >
                  The traveler has declined to provide his contact information to the airline.
                </Typography>
              )}
              {traveler.trustedTravelerProgram && (
                <DataDisplay
                  label="Trusted traveler number"
                  value={`${traveler.trustedTravelerProgram.number} (${traveler.trustedTravelerProgram.countryCodeIso})`}
                />
              )}

              {traveler.loyaltyPrograms.find((program) => !!program.number) && (
                <>
                  <Typography sx={{ fontWeight: 500, mt: 1 }}>Frequent flyer program</Typography>
                  {traveler.loyaltyPrograms.map((program, index) => (
                    <Fragment key={index}>
                      <DataDisplay
                        label={
                          airlinesFrequentFlyer.find((loyaltyProgram) => loyaltyProgram.value === program.programCode)
                            ?.name || program.airlineName
                        }
                        value={program.number}
                      />
                    </Fragment>
                  ))}
                </>
              )}
            </Box>
            {hasTravelerBaggage(traveler, orderItem.productSummary.itineraries) && (
              <>
                <Grid item xs={12} mt={1}>
                  <SectionTitle title={'Baggages'} variant="body2" sx={{ mb: 1 }} />
                </Grid>

                {orderItem.productSummary.itineraries.map((itinerary, index) => {
                  const { cabinBags, checkedBags } = getIncludedBaggages(itinerary, traveler.id);

                  const extraBaggages = traveler.baggageOptionSelection.filter((bag) =>
                    bag.itineraryIds.find((itineraryId) => itinerary.id === itineraryId),
                  );

                  return (
                    <>
                      {(cabinBags.length > 0 || checkedBags.length > 0 || extraBaggages.length > 0) && (
                        <Grid item xs={6} key={index} sx={{ lineHeight: 1 }}>
                          <Typography variant="caption" sx={{ fontWeight: 600, lineHeight: 1.1 }}>
                            {`${itinerary.segments[0].origin.airportCode} `}-
                            {` ${itinerary.segments[itinerary.segments.length - 1].destination.airportCode}`}
                          </Typography>
                          {(cabinBags.length > 0 || checkedBags.length > 0) && (
                            <Box>
                              <Typography
                                variant="caption"
                                sx={(theme) => ({
                                  fontWeight: 500,
                                  lineHeight: 1.1,
                                  color: theme.customPalette.text.label,
                                })}
                              >
                                Included
                              </Typography>
                              {cabinBags.map((bag, index) => (
                                <Typography
                                  fontWeight={600}
                                  sx={{
                                    opacity: 1,
                                    color: (theme) => alpha(theme.palette.common.black, 0.7),
                                  }}
                                  key={`included-cabin-bag-${index}`}
                                >
                                  {`${bag.bag.quantity} x Carry-on Bag`}
                                  {bag.segments.join('|') !== itinerary.id && (
                                    <Typography variant="body2" component="span" sx={{ fontWeight: 500 }}>
                                      <SegmentCodeByIds segmentsIds={bag.segments} itinerary={itinerary} />
                                    </Typography>
                                  )}
                                </Typography>
                              ))}
                              {checkedBags.map((bag, index) => (
                                <Typography
                                  fontWeight={600}
                                  sx={{ opacity: 1, color: (theme) => alpha(theme.palette.common.black, 0.7) }}
                                  key={`included-checked-bag-${index}`}
                                >
                                  {`${bag.bag.quantity} x Checked Bag`}
                                  {bag.segments.join('|') !== itinerary.id && (
                                    <Typography variant="body2" component="span" sx={{ fontWeight: 500 }}>
                                      <SegmentCodeByIds segmentsIds={bag.segments} itinerary={itinerary} />
                                    </Typography>
                                  )}
                                </Typography>
                              ))}
                            </Box>
                          )}
                          {extraBaggages.length > 0 && (
                            <Box>
                              <Typography variant="caption" sx={{ color: 'secondary.dark' }}>
                                Extra
                              </Typography>
                              {extraBaggages.map((baggage, index) => (
                                <Typography
                                  fontWeight={600}
                                  sx={{ opacity: 1, color: (theme) => alpha(theme.palette.common.black, 0.7) }}
                                  key={`extra-bag-${index}`}
                                >
                                  {`${baggage.baggageOption.quantity} x `}
                                  {baggage.baggageOption.weight > 0 &&
                                    baggage.baggageOption.weightUnit &&
                                    `${baggage.baggageOption.weight} ${baggage.baggageOption.weightUnit} `}
                                  {getBaggageType(baggage.baggageOption.type)}
                                </Typography>
                              ))}
                            </Box>
                          )}
                        </Grid>
                      )}
                    </>
                  );
                })}
              </>
            )}
          </Grid>
        ))}
      </Grid>

      {purchaseSummary && (
        <Box sx={{ my: 4 }}>
          <OrderItemPricingSummary purchaseSummary={purchaseSummary} orderItem={orderItem} />
        </Box>
      )}

      {orderItem.productSummary.fareRules.length > 0 && (
        <>
          <SectionTitle title="Flexibility" variant="h6" />
          <FareRules fareRules={orderItem.productSummary.fareRules} />
        </>
      )}

      {orderItem.productSummary.termsAndConditions && (
        <>
          <Button
            onClick={() => setShowTermsDetails(!showTermsDetails)}
            sx={{
              display: 'flex',
              textTransform: 'none',
              width: 'fit-content',
              p: 0,
              mt: 0.5,
            }}
            disableRipple
            variant="text"
          >
            Policies and Terms & Conditions
          </Button>
          <Dialog
            open={showTermsDetails}
            onClose={() => setShowTermsDetails(!showTermsDetails)}
            scroll="paper"
            maxWidth="md"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            PaperProps={{
              style: { maxHeight: 'min(80vh, 1200px)' },
            }}
          >
            <DialogTitle id="scroll-dialog-title">Policies and Terms & Conditions</DialogTitle>
            <DialogContent>
              <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
                <Terms
                  terms={orderItem.productSummary.termsAndConditions}
                  itineraries={orderItem.productSummary.itineraries}
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowTermsDetails(!showTermsDetails)} data-testid="TermDialogCloseButton">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};

const ItineraryOverview = ({ itinerary }: { itinerary: Itinerary }) => {
  const { formatDate } = useFormat();
  const [showDetails, setShowDetails] = useState(false);
  const originLocation = itinerary.segments[0].origin;
  const destinationLocation = itinerary.segments[itinerary.segments.length - 1].destination;

  const daysToDestination = getDaysToDestination(originLocation.localTime, destinationLocation.localTime);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: { xs: 1, md: 0.5 },
          alignItems: { xs: 'start', md: 'center' },
          justifyContent: 'space-between',
          mr: { md: 2 },
          mb: 0.5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: { md: '300px', lg: '450px' },
          }}
        >
          <Typography fontSize="0.875rem" fontWeight={600}>
            {`${formatLocation(originLocation)} (${originLocation.airportCode}${
              originLocation.terminal ? `, Terminal ${originLocation.terminal}` : ''
            }) - ${formatLocation(destinationLocation)} (${destinationLocation.airportCode}${
              destinationLocation.terminal ? `, Terminal ${destinationLocation.terminal}` : ''
            })`}
          </Typography>
          <Typography fontSize="0.875rem" fontWeight={600}>
            {formatDate(originLocation.localTime, true, 'EEE, MMM d, yyy')}
          </Typography>
          <Typography fontWeight={600}>
            {`${formatDate(originLocation?.localTime, true, 'hh:mma')?.toLowerCase()} `}-
            {` ${formatDate(destinationLocation?.localTime, true, 'hh:mma')?.toLowerCase()}`}
            {!!daysToDestination && (
              <Box component="sup" fontSize="0.75rem" fontWeight={400}>
                {daysToDestination > 0 && `+${daysToDestination}`}
                {daysToDestination < 0 && `${daysToDestination}`}
              </Box>
            )}
          </Typography>

          <Button
            onClick={() => setShowDetails(!showDetails)}
            sx={{
              display: 'flex',
              textTransform: 'none',
              width: 'fit-content',
              p: 0,
            }}
            disableRipple
            variant="text"
          >
            {!showDetails ? `See more` : `See less`}
            <KeyboardArrowDownIcon
              fontSize="small"
              sx={{
                ml: 1,
                alignSelf: { xs: showDetails ? 'flex-start' : 'center', sm: 'flex-start' },
                transform: showDetails ? 'rotate(-180deg)' : 'rotate(0deg)',
                transition: 'transform 0.5s ease',
                fontWeight: 500,
                alignItems: 'center',
                m: 'auto 0',
              }}
            />
          </Button>

          <Typography variant="body2" sx={{ display: { xs: 'block', md: 'none' } }}>
            {`${formatLocation(originLocation)} (${originLocation.airportCode}) - ${formatLocation(
              destinationLocation,
            )} (${destinationLocation.airportCode})`}
            ({formatDuration(itinerary.totalDurationInMinutes)}
            {itinerary.segments.length > 1 &&
              `, ${itinerary.segments.length - 1} ${itinerary.segments.length - 1 === 1 ? 'stop' : 'stops'}`}
            )
          </Typography>
        </Box>
        <Box sx={{ minWidth: '75px' }}>
          {itinerary.numberOfStops > 0 ? (
            <>
              <Typography fontWeight={500} fontSize="0.875rem" lineHeight={1.3}>
                {`${itinerary.numberOfStops} ${itinerary.numberOfStops === 1 ? 'stop' : 'stops'}`}
              </Typography>
              <Typography lineHeight={1.3}>
                {itinerary.segments.map((segment, index) => (
                  <Fragment key={index}>
                    {segment.preFlightLayoverDurationInMinutes && (
                      <Tooltip
                        title={`${formatDuration(segment.preFlightLayoverDurationInMinutes)} layover in ${
                          segment.origin.locationName
                        }`}
                        arrow
                      >
                        <Typography component="span" fontSize="0.875rem" sx={{ color: '#5a6872', cursor: 'initial' }}>
                          {segment.origin.airportCode}
                          {index !== itinerary.segments.length - 1 ? ', ' : ''}
                        </Typography>
                      </Tooltip>
                    )}
                  </Fragment>
                ))}
              </Typography>
            </>
          ) : (
            <Typography fontWeight={500} fontSize="0.875rem">
              Nonstop
            </Typography>
          )}
        </Box>

        <Box sx={{ display: { xs: 'none', md: 'block' }, minWidth: '75px' }}>
          <Typography fontWeight={500} fontSize="0.875rem" lineHeight={1.3}>
            {formatDuration(itinerary.totalDurationInMinutes)}
          </Typography>
          <Box sx={{ display: 'flex', fontSize: '0.875rem' }}>
            <Tooltip arrow title={formatLocation(originLocation)}>
              <Typography sx={{ color: '#5a6872', fontSize: '0.875rem' }}>{originLocation.airportCode}</Typography>
            </Tooltip>
            -
            <Tooltip arrow title={formatLocation(destinationLocation)}>
              <Typography sx={{ color: '#5a6872', fontSize: '0.875rem' }}>{destinationLocation.airportCode}</Typography>
            </Tooltip>
          </Box>
        </Box>
      </Box>

      <Collapse in={showDetails} timeout="auto" unmountOnExit>
        <Divider sx={{ mt: 1, mb: 0.5 }} />
        {itinerary.segments.map((segment, index) => {
          const daysToDestination = getDaysToDestination(segment.origin.localTime, segment.destination?.localTime);
          const daysBetweenSegments =
            index > 0 && index <= itinerary.segments.length
              ? getDaysToDestination(itinerary.segments[index - 1].destination.localTime, segment.origin.localTime)
              : undefined;

          return (
            <Box sx={{ pt: 0, px: 1 }} key={index}>
              {segment.preFlightLayoverDurationInMinutes && (
                <>
                  <Divider sx={{ my: 1 }} />
                  <Box
                    display="flex"
                    flexDirection={{ xs: 'column', sm: 'row' }}
                    alignItems={'center'}
                    gap={{ md: 1 }}
                    flexWrap="wrap"
                  >
                    <Typography fontSize="0.875rem">
                      {`${formatDuration(segment.preFlightLayoverDurationInMinutes)} • 
                    ${
                      segment.airportChangeNeeded
                        ? `Change airport from  ${segment.origin.locationName} to ${segment.destination.locationName}`
                        : `Change planes in ${formatLocation(segment.origin)} ${segment.origin.airportCode}`
                    }`}
                    </Typography>

                    {segment.airportChangeNeeded && (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          width: 'fit-content',
                          color: '#820D17',
                          borderRadius: 1,
                          fontWeight: 600,
                        }}
                      >
                        Self-transfer
                      </Box>
                    )}
                    {segment.isLongLayover && (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          width: 'fit-content',
                          color: '#820D17',
                          borderRadius: 1,
                          fontWeight: 600,
                        }}
                      >
                        Long connection
                      </Box>
                    )}
                  </Box>
                  <Divider sx={{ my: 1 }} />
                </>
              )}
              <Box sx={{ pt: 0.5 }}>
                <Typography fontSize="0.875rem">
                  {`${segment.origin.locationName} `}
                  <Typography component="span" fontSize="0.7rem" color="#5a6872">
                    ({formatLocation(segment.origin)} - {segment.origin.airportCode}
                    {segment.origin.terminal && `, Terminal ${segment.origin.terminal}`})
                  </Typography>
                  {` - ${segment.destination.locationName} `}
                  <Typography component="span" fontSize="0.7rem" color="#5a6872">
                    ({formatLocation(segment.destination)} - {segment.destination.airportCode}
                    {segment.destination.terminal && `, Terminal ${segment.destination.terminal}`})
                  </Typography>
                </Typography>

                <Typography fontSize="0.875rem">
                  {`${formatDate(segment.origin?.localTime, true, 'hh:mma')?.toLowerCase()} `}-
                  {` ${formatDate(segment.destination?.localTime, true, 'hh:mma')?.toLowerCase()}`}
                  {!!daysToDestination && (
                    <Box component="sup" fontSize="0.75rem" fontWeight={400}>
                      {daysToDestination > 0 && `+${daysToDestination}`}
                    </Box>
                  )}
                </Typography>
                {!!daysBetweenSegments && daysBetweenSegments !== 0 && (
                  <Typography fontSize="0.7rem" fontWeight={300} color="error.main">
                    Departs {formatDate(segment.origin.localTime, true, 'EEE, MMM d')}
                  </Typography>
                )}
                {!!daysToDestination && daysToDestination !== 0 && (
                  <Typography fontSize="0.7rem" lineHeight={1} fontWeight={300} color="error.main">
                    Arrives {formatDate(segment.destination?.localTime, true, 'EEE, MMM d')}
                  </Typography>
                )}

                <Typography color="#5a6872" fontSize="0.75rem" mt="0.25">
                  Flight number: {segment.flightNumber}
                </Typography>
                <Typography color="#5a6872" fontSize="0.75rem" mt="0.25">
                  {segment.marketingAirline.name}
                </Typography>
                {segment.marketingAirline.name !== segment.operatingAirline.name && (
                  <Typography color="#5a6872" fontSize="0.75rem" mt="0.25">
                    Operated by {segment.operatingAirline.name}
                  </Typography>
                )}

                <Typography color="#5a6872" fontSize="0.75rem">
                  Plane type {segment.aircraft?.name}
                </Typography>

                {segment.brandedFare && segment.cabinClass.code && (
                  <Typography color="#5a6872" fontSize="0.75rem" fontWeight={600}>
                    {`${segment.brandedFare} (${segment.cabinClass.code})`}
                  </Typography>
                )}
                <Typography color="#5a6872" fontSize="0.75rem" sx={{ lineHeight: 1.2, mb: { sm: 1 } }}>
                  Cabin: {getCabinClassName(segment.cabinClass)}
                </Typography>
              </Box>
            </Box>
          );
        })}
        <Divider sx={{ mt: 1 }} />
      </Collapse>
    </>
  );
};

const groupTerms = (termsAndConditions: TermsAndConditions[]) => {
  const contentMap = new Map();

  termsAndConditions.forEach((term) => {
    term.content.forEach((item) => {
      const key = `${item.label}-${item.content}`;
      if (!contentMap.has(key)) {
        contentMap.set(key, {
          label: item.label,
          content: item.content,
          segmentIds: [term.segmentId],
        });
      } else {
        const existing = contentMap.get(key);
        if (!existing.segmentIds.includes(term.segmentId)) {
          existing.segmentIds.push(term.segmentId);
        }
      }
    });
  });

  return Array.from(contentMap.values());
};

const hasTravelerBaggage = (traveler: Traveler, itineraries: Itinerary[]) => {
  const hasBaggageOptionSelection = traveler.baggageOptionSelection.length > 0;

  const hasIncludedBags = itineraries.some((itinerary) =>
    itinerary.segments.some(
      (segment) =>
        segment.includedCabinBags.some((bag) => bag.travelerId === traveler.id) ||
        segment.includedCheckedBags.some((bag) => bag.travelerId === traveler.id),
    ),
  );

  return hasBaggageOptionSelection || hasIncludedBags;
};

const getBaggageType = (baggage: string) => {
  if (baggage === 'CheckedBag') {
    return 'Checked Bag';
  }
  if (baggage === 'CabinBag') {
    return 'Cabin Bag';
  }
};

const getIncludedBaggages = (itinerary: Itinerary, travelerId: string) => {
  const checkedBagsMap = new Map();
  const cabinBagsMap = new Map();

  itinerary.segments.forEach((segment) => {
    segment.includedCheckedBags
      .filter((bag) => bag.travelerId === travelerId)
      .forEach((bag) => {
        const bagKey = JSON.stringify(bag);

        if (checkedBagsMap.has(bagKey)) {
          checkedBagsMap.get(bagKey).segments.push(segment.id);
        } else {
          checkedBagsMap.set(bagKey, {
            bag: bag,
            segments: [segment.id],
          });
        }
      });

    segment.includedCabinBags
      .filter((bag) => bag.travelerId === travelerId)
      .forEach((bag) => {
        const bagKey = JSON.stringify(bag);

        if (cabinBagsMap.has(bagKey)) {
          cabinBagsMap.get(bagKey).segments.push(segment.id);
        } else {
          cabinBagsMap.set(bagKey, {
            bag: bag,
            segments: [segment.id],
          });
        }
      });
  });

  return {
    cabinBags: Array.from(cabinBagsMap.values()) as ItineraryBaggage[],
    checkedBags: Array.from(checkedBagsMap.values()) as ItineraryBaggage[],
  };
};

const Terms = ({ terms, itineraries }: TermsProps) => {
  const groupedContent: TermsContent[] = groupTerms(terms);

  return (
    <>
      {groupedContent.map((group, index) => {
        return (
          <Fragment key={index}>
            <Typography sx={{ color: '#647582', fontSize: '0.875rem', fontWeight: 600 }}>
              {group.label}
              <SegmentsCode itineraries={itineraries} content={group} />
            </Typography>
            <TermText content={group} />
          </Fragment>
        );
      })}
    </>
  );
};

const TermText = ({ content }: TermsContentProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <Typography
        sx={{
          color: '#647582',
          fontSize: '0.875rem',
          display: '-webkit-box',
          WebkitLineClamp: isExpanded ? 'none' : 3,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {content.content}
      </Typography>
      <Button
        variant="text"
        sx={{
          fontSize: '0.875rem',
          padding: 0,
          mb: 1,
          width: 'fit-content',
          textTransform: 'none',
          justifyContent: 'start',
        }}
        onClick={toggleExpanded}
      >
        {!isExpanded ? 'Read more' : 'Hide'}
      </Button>
    </>
  );
};

const SegmentsCode = ({ content, itineraries }: SegmentProps) => {
  const findSegmentById = (segmentId: string) => {
    return itineraries.flatMap((itinerary) => itinerary.segments).find((segment) => segment.id === segmentId);
  };

  return (
    <>
      {` (`}
      {content.segmentIds.map((segmentId, index) => {
        const segment = findSegmentById(segmentId);
        return (
          <Fragment key={index}>
            {segment?.origin.airportCode} - {segment?.destination.airportCode}
            {content.segmentIds.length - 1 !== index && <>, </>}
          </Fragment>
        );
      })}
      {`)`}
    </>
  );
};

const SegmentCodeByIds = ({ segmentsIds, itinerary }: SegmentCodeByIdsProps) => {
  const findSegmentById = (segmentId: string) => {
    return itinerary.segments.find((segment) => segment.id === segmentId);
  };

  return (
    <>
      {` (`}
      {segmentsIds.map((segmentId, index) => {
        const segment = findSegmentById(segmentId);
        return (
          <Fragment key={index}>
            {segment?.origin.airportCode}-{segment?.destination.airportCode}
            {segmentsIds.length - 1 !== index && <>, </>}
          </Fragment>
        );
      })}
      {`)`}
    </>
  );
};

const formatDuration = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const minutesLeft = minutes % 60;
  return `${hours}h ${minutesLeft}m`;
};

const formatLocation = (location: SegmentEndPoint) => {
  const airportName = location.airportName?.replace(`${location.airportCode}-`, '');
  return !!airportName ? airportName : !!location.locationName ? location.locationName : location.locationCode;
};

const getDaysToDestination = (originDateInput: Date | string, destinationDateInput: Date | string): number => {
  const originDate = new Date(originDateInput);
  const destinationDate = new Date(destinationDateInput);

  const originStartOfDayUTC = new Date(originDate.getFullYear(), originDate.getMonth(), originDate.getDate()).getTime();
  const destinationStartOfDayUTC = new Date(
    destinationDate.getFullYear(),
    destinationDate.getMonth(),
    destinationDate.getDate(),
  ).getTime();

  const daysDifference = differenceInDays(destinationStartOfDayUTC, originStartOfDayUTC);

  return daysDifference;
};

const getCabinClassName = (cabinClass: CabinClass) => {
  switch (cabinClass.id) {
    case 'azL78KfdZgCwQruY7LT92C':
      return 'Economy';
    case '8eGb3jmsmvBgfBpDmrLehH':
      return 'Premium Economy';
    case 'vVYzZRsngBUnK5FNTDBgMA':
      return 'Business';
    case 'azqtRXGPNmgwddSSYFcRLV':
      return 'First Class';
    default:
      return cabinClass.name;
  }
};

const FareRules = ({ fareRules }: FareRulesProps) => {
  const rules: FareRule[] = [...fareRules].sort((a: FareRule, b: FareRule) =>
    a.isApplicable === b.isApplicable ? 0 : a.isApplicable ? -1 : 1,
  );

  return (
    <>
      {rules.length > 0 &&
        rules.map((fareRule: FareRule) => (
          <Fragment key={fareRule.type}>
            <FareRuleDetails fareRule={fareRule} />
          </Fragment>
        ))}
    </>
  );
};

const FareRuleDetails = ({ fareRule }: FareRuleProps) => {
  const { formatCurrency } = useFormat();

  let ruleText = '';
  let description = null;
  switch (fareRule.type) {
    case FareRuleType.Refund:
      if (fareRule.isApplicable) {
        if (fareRule.penaltyAmount.amount === 0) {
          ruleText = 'Fully refundable';
          description = "You'll get a full refund.";
        } else {
          ruleText = 'Partially refundable';
          description = `Penalty: ${formatCurrency(fareRule.penaltyAmount.amount, fareRule.penaltyAmount.currency, 2)}`;
        }
      } else {
        ruleText = 'Non-refundable';
      }
      break;
    case FareRuleType.Exchange:
      if (fareRule.isApplicable) {
        if (fareRule.penaltyAmount.amount === 0) {
          ruleText = 'Changes included';
          description = 'only pay fare difference';
        } else {
          ruleText = 'Change fee:';
          description = formatCurrency(fareRule.penaltyAmount.amount, fareRule.penaltyAmount.currency, 2);
        }
      } else {
        ruleText = 'Change not allowed';
      }
      break;

    case FareRuleType.Cancellation:
      if (fareRule.isApplicable) {
        if (fareRule.penaltyAmount.amount === 0) {
          ruleText = 'Cancellation included';
          description = 'There is no fee to cancel the booking.';
        } else {
          ruleText = 'Cancellation';
          description = `Penalty: ${formatCurrency(fareRule.penaltyAmount.amount, fareRule.penaltyAmount.currency, 2)}`;
        }
      } else {
        ruleText = 'Cancellation not allowed';
      }
      break;

    case FareRuleType.Rebook:
      if (fareRule.isApplicable) {
        if (fareRule.penaltyAmount.amount === 0) {
          ruleText = 'Rebook included';
        } else {
          ruleText = 'Rebook fee:';
          description = formatCurrency(fareRule.penaltyAmount.amount, fareRule.penaltyAmount.currency, 2);
        }
      } else {
        ruleText = 'Rebook not allowed';
      }
      break;

    case FareRuleType.Reissue:
      if (fareRule.isApplicable) {
        if (fareRule.penaltyAmount.amount === 0) {
          ruleText = 'Reissue included';
        } else {
          ruleText = 'Reissue fee:';
          description = formatCurrency(fareRule.penaltyAmount.amount, fareRule.penaltyAmount.currency, 2);
        }
      } else {
        ruleText = 'Reissue not allowed';
      }
      break;

    case FareRuleType.Revalidation:
      if (fareRule.isApplicable) {
        if (fareRule.penaltyAmount.amount === 0) {
          ruleText = 'Revalidation included';
        } else {
          ruleText = 'Revalidation fee:';
          description = formatCurrency(fareRule.penaltyAmount.amount, fareRule.penaltyAmount.currency, 2);
        }
      } else {
        ruleText = 'Revalidation not allowed';
      }
      break;
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 0.5 }}>
      {fareRule.isApplicable ? <CheckIcon color="primary" fontSize="small" /> : <CloseIcon fontSize="small" />}
      <DataDisplay label={ruleText} value={description} />
    </Box>
  );
};
