import { useRef } from 'react';
import { useMutation } from 'react-query';
import { addMinutes, format } from 'date-fns';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Box, Grid, Link, Paper } from '@mui/material';
import { ExportCriteria } from '@one/api-models/lib/Admin/Reports/DelinquencyReport/ExportCriteria';

import { ApiError, FileResponse } from 'apiAccess/api-client';
import { Loading } from 'common';
import { ActionButton } from 'common/ActionButton';
import { PageHeader } from 'common/layout/components/PageHeader';
import { useApiHelpers } from 'hooks/useApiHelpers';
import { useToastMessage } from 'hooks/useToastMessage';

export const DelinquencyReport = () => {
  const { api } = useApiHelpers();
  const { addApiError } = useToastMessage();
  const linkRef = useRef<HTMLAnchorElement>(null);

  const testIdPrefix = 'DelinquencyReport';

  const exportMutation = useMutation<FileResponse, ApiError, ExportCriteria, unknown>(
    async (request: ExportCriteria) => {
      return await api.reports.delinquencyReport(request);
    },
    {
      onSuccess: async (response: FileResponse, request: ExportCriteria) => {
        if (linkRef === undefined || linkRef === null || linkRef.current === null) return;

        const fileName =
          response.fileName ??
          `Delinquency_Report_${format(
            addMinutes(new Date(), new Date().getTimezoneOffset()),
            'MM-dd-yyyy_HH;mm',
          )}.csv`;

        linkRef.current.href = window.URL.createObjectURL(response.blob);
        linkRef.current.setAttribute('download', fileName);
        linkRef.current.click();
      },
      onError: addApiError,
    },
  );

  const click = () => {
    exportMutation.mutate({ brandKey: '' });
  };

  return (
    <Box>
      <PageHeader title="Delinquency Report" testId={testIdPrefix} />
      <Paper sx={{ mb: 2, p: 3 }}>
        <Grid container justifyContent={{ xs: 'center', md: 'flex-start' }} alignItems="center">
          <Grid item sx={{ mt: { xs: 2, md: 0 } }}>
            <>
              {exportMutation.isLoading && <Loading message="Please wait..." />}
              <ActionButton
                icon={<FileDownloadOutlinedIcon />}
                loading={exportMutation.isLoading}
                onClick={click}
                testId={`${testIdPrefix}Export`}
              >
                Export
              </ActionButton>
              <Link ref={linkRef} style={{ display: 'none' }} data-testid={`${testIdPrefix}DownloadLink`}>
                download link
              </Link>
            </>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};
