import { useEffect, useState } from 'react';
import { useIsMutating, useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import { Alert, Box, Button, Link, Paper } from '@mui/material';
import { GridColDef, GridPaginationModel } from '@mui/x-data-grid-pro';
import { ListingData } from '@one/api-models/lib/Admin/Common/ListingData';
import { PromotionSummary } from '@one/api-models/lib/Campaign/Promotion/PromotionSummary';
import { ListRequest } from '@one/api-models/lib/Campaign/Promotion/Request/ListRequest';
import { ListResponse } from '@one/api-models/lib/Campaign/Promotion/Response/ListResponse';

import { selectActiveBrand } from 'store/slices/applicationDataSlice';

import { ApiError } from 'apiAccess/api-client';
import { Loading } from 'common';
import { DataGrid } from 'common/dataGrid/DataGrid';
import { PageHeader } from 'common/layout/components/PageHeader';
import { useApiHelpers } from 'hooks/useApiHelpers';
import { useFormat } from 'hooks/useFormat';
import { useToastMessage } from 'hooks/useToastMessage';

import { PromotionStatus } from './components/PromotionStatus';
import { PromotionTypeValues } from './components/promotionTabs/promotionHelpers';

const defaultPageSize = 25;

export const PromotionsListView = () => {
  const navigate = useNavigate();
  const { formatDate } = useFormat();
  const { api } = useApiHelpers();
  const { apiErrorHandler } = useToastMessage();
  const [page, setPage] = useState(0);
  const [totalRowsCount, setTotalRowsCount] = useState(0);
  const isLoading = useIsMutating({ mutationKey: 'promotionsMutation' }) > 0;
  const [promotions, setPromotions] = useState<PromotionSummary[] | undefined>(undefined);
  const activeBrand = useSelector(selectActiveBrand);
  const brandKey = activeBrand?.key || '';
  const testIdPrefix = 'Promotions';

  useEffect(() => {
    promotionsMutation.mutateAsync({
      brandKey,
      listHandling: {
        skip: page * defaultPageSize,
        take: defaultPageSize,
        ascending: true,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, activeBrand]);

  const promotionsMutation = useMutation<ListResponse, ApiError, ListRequest, unknown>(
    (request) => api.promotions.load(request),
    {
      mutationKey: 'promotionsMutation',
      onSuccess: (value: ListResponse) => {
        setPromotions((value.promotionSummaries as ListingData<PromotionSummary>).items);
        setTotalRowsCount((value.promotionSummaries as ListingData<PromotionSummary>).itemCount);
      },
      onError: apiErrorHandler,
    },
  );

  const handleClick = (promotion: PromotionSummary) => {
    navigate(`/marketing/promotions/${promotion.id}`);
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Promotion ID',
      flex: 1,
      sortable: false,
    },
    {
      field: 'name',
      headerName: 'Promotion Name',
      flex: 2,
      sortable: false,
    },
    {
      field: 'type',
      headerName: 'Promotion Type',
      flex: 1,
      sortable: false,
      renderCell: (params: any) => <>{PromotionTypeValues[params?.row?.type]?.label}</>,
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      flex: 1,
      sortable: false,
    },
    {
      field: 'createdAt',
      headerName: 'Created On (UTC)',
      flex: 1,
      sortable: false,
      renderCell: (params: any) => <>{formatDate(params?.row?.createdAt, false, 'MMM dd, yyyy') || ''}</>,
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      flex: 1,
      renderCell: (params: any) => <PromotionStatus status={params?.row?.status} />,
    },
    {
      field: 'updatedBy',
      headerName: 'Updated By',
      flex: 1,
      sortable: false,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated On (UTC)',
      flex: 1,
      sortable: false,
      renderCell: (params: any) => <>{formatDate(params?.row?.updatedAt, false, 'MMM dd, yyyy') || ''}</>,
    },
  ];

  return (
    <Box>
      {isLoading && <Loading message={'Loading...'} />}
      <PageHeader
        title="Promotions"
        titleAside={
          <Link
            component={RouterLink}
            to="/marketing/promotions/add"
            underline="none"
            data-testid={`${testIdPrefix}NewPromotionLink`}
          >
            <Button
              variant="contained"
              size="medium"
              disableRipple
              startIcon={<AddIcon />}
              data-testid={`${testIdPrefix}NewPromotionButton`}
            >
              New Promotion
            </Button>
          </Link>
        }
        testId={testIdPrefix}
      />
      <Paper sx={{ p: 3 }}>
        {/* V1 - no filters available */}
        {/* <PromotionsSearchForm /> */}
        {!isLoading ? (
          <>
            {promotions && promotions.length > 0 ? (
              <DataGrid
                rows={promotions}
                columns={columns}
                paginationMode="server"
                initialState={{
                  pagination: {
                    paginationModel: {
                      page: page,
                      pageSize: defaultPageSize,
                    },
                  },
                }}
                rowCount={totalRowsCount}
                onPaginationModelChange={(data: GridPaginationModel) => setPage(data.page)}
                hideFooter={totalRowsCount <= defaultPageSize}
                onRowClick={(param: any, event: any) => handleClick(param.row as PromotionSummary)}
                sx={{
                  '& .MuiDataGrid-row': {
                    cursor: 'pointer',
                  },
                }}
              />
            ) : (
              <Alert severity="info" variant="outlined">
                No promotions available.
              </Alert>
            )}
          </>
        ) : null}
      </Paper>
    </Box>
  );
};
