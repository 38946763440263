import { SetStateAction, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { addMinutes, format } from 'date-fns';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Box, Grid, Link, Paper } from '@mui/material';
import { ExportCriteria } from '@one/api-models/lib/Admin/Reports/MarketingReport/ExportCriteria';

import { selectActiveBrand, selectActivePartner } from 'store/slices/applicationDataSlice';

import { ApiError, FileResponse } from 'apiAccess/api-client';
import { Loading } from 'common';
import { ActionButton } from 'common/ActionButton';
import UncontrolledDatePicker from 'common/inputs/UncontrolledDatePicker';
import { PageHeader } from 'common/layout/components/PageHeader';
import { useApiHelpers } from 'hooks/useApiHelpers';
import { useFormat } from 'hooks/useFormat';
import { useToastMessage } from 'hooks/useToastMessage';

export const MarketingReport = () => {
  const { api } = useApiHelpers();
  const { addApiError } = useToastMessage();
  const { getEndDate, getStartDate } = useFormat();

  const activePartner = useSelector(selectActivePartner);
  const activeBrand = useSelector(selectActiveBrand);

  const currentDay = new Date();
  const [startDate, setStartDate] = useState<Date | undefined>(currentDay);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const linkRef = useRef<HTMLAnchorElement>(null);

  const testIdPrefix = 'MarketingReport';

  const exportMutation = useMutation<FileResponse, ApiError, ExportCriteria, unknown>(
    async (request: ExportCriteria) => {
      return await api.reports.marketingReportExport(request);
    },
    {
      onSuccess: async (response: FileResponse, request: ExportCriteria) => {
        if (linkRef?.current == null) return;

        const fileName =
          response.fileName ??
          `MarketingReport_UTC${format(
            addMinutes(new Date(), new Date().getTimezoneOffset()),
            'yyyy-MM-dd_HH;mm',
          )}.csv`;

        linkRef.current.href = window.URL.createObjectURL(response.blob);
        linkRef.current.setAttribute('download', fileName);
        linkRef.current.click();
      },
      onError: addApiError,
    },
  );

  const handleExportButtonClick = () => {
    exportMutation.mutate({
      startDate: getStartDate(startDate),
      endDate: getEndDate(endDate),
      partnerKey: activePartner?.key,
      brandKey: activeBrand?.key ?? '',
    });
  };

  return (
    <Box>
      {exportMutation.isLoading && <Loading message="Please wait..." />}
      <PageHeader title="Marketing Report" testId={testIdPrefix} />
      <Paper sx={{ mb: 2, p: 3 }}>
        <Grid container justifyContent="space-between" alignItems="center" gap={2}>
          <Grid item>
            <Grid container alignItems="center" gap={2}>
              <UncontrolledDatePicker
                label="Start Date"
                placeholder="Start Date"
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(newValue as SetStateAction<Date | undefined>);
                  if (endDate && newValue && new Date(newValue).getTime() > new Date(endDate).getTime()) {
                    setEndDate(newValue);
                  }
                }}
                maxDate={currentDay}
                testId={`${testIdPrefix}Start`}
              />
              <UncontrolledDatePicker
                label="End Date"
                placeholder="End Date"
                value={endDate}
                onChange={(newValue) => {
                  setEndDate(newValue as SetStateAction<Date | undefined>);
                  if (startDate && newValue && new Date(newValue).getTime() < new Date(startDate).getTime()) {
                    setStartDate(newValue);
                  }
                }}
                maxDate={currentDay}
                testId={`${testIdPrefix}End`}
              />
            </Grid>
          </Grid>
          <Grid item>
            <ActionButton
              icon={<FileDownloadOutlinedIcon />}
              onClick={handleExportButtonClick}
              testId={`${testIdPrefix}Export`}
            >
              Export
            </ActionButton>
            <Link ref={linkRef} style={{ display: 'none' }} data-testid={`${testIdPrefix}DownloadLink`}>
              download link
            </Link>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};
