import { Control, FieldValues, useForm } from 'react-hook-form';
import { useIsMutating, useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, MenuItem } from '@mui/material';
import { PromoCodeAssignment } from '@one/api-models/lib/Campaign/PromoCodeDefinition/PromoCodeAssignment';
import { GenerateCodesRequest } from '@one/api-models/lib/Campaign/PromoCodeDefinition/Request/GenerateCodesRequest';
import { GenerateCodesResponse } from '@one/api-models/lib/Campaign/PromoCodeDefinition/Response/GenerateCodesResponse';

import { selectActiveBrand } from 'store/slices/applicationDataSlice';

import { ApiError } from 'apiAccess/api-client';
import { Loading } from 'common';
import { ControlledInternationalCountryCallingCodesSelect } from 'common/inputs/defaultFields/ControlledInternationalCountryCallingCodesSelect';
import { ControlledPhoneNumberInput } from 'common/inputs/defaultFields/ControlledPhoneNumberInput';
import ControlledSelect from 'common/inputs/defaultFields/ControlledSelect';
import ControlledTextField from 'common/inputs/defaultFields/ControlledTextField';
import { useApiHelpers } from 'hooks/useApiHelpers';
import { useToastMessage } from 'hooks/useToastMessage';
import { isPhoneNumberValid } from 'utils/validatePhoneNumber';

interface GeneratePromoCodeForm {
  quantity: number;
  assignment?: number | null;
  email?: string;
  phoneNumber?: string | null;
  internationalCountryCallingCode?: string;
  memberId?: string;
}

const validationSchema: yup.SchemaOf<GeneratePromoCodeForm> = yup.object().shape({
  quantity: yup
    .number()
    .transform((_, val) => (val !== '' ? Number(val) : undefined))
    .nullable()
    .required('Quantity is required.')
    .typeError('Invalid number'),
  assignment: yup
    .number()
    .transform((_, val) => (val !== '' ? Number(val) : undefined))
    .nullable()
    .typeError('Invalid number'),
  email: yup
    .string()
    .trim()
    .when(['assignment'], {
      is: (assignedTo: number) => assignedTo === PromoCodeAssignment.Email,
      then: yup.string().trim().required('Email is required.').email('Must be a valid email.'),
    }),
  phoneNumber: yup
    .string()
    .trim()
    .when(['assignment'], {
      is: (assignedTo: number) => assignedTo === PromoCodeAssignment.PhoneNumber,
      then: yup
        .string()
        .trim()
        .required('Phone number is required.')
        .test('phoneNumber', 'Phone number is not valid.', (value) => isPhoneNumberValid(value))
        .nullable(),
    }),
  internationalCountryCallingCode: yup
    .string()
    .trim()
    .when(['assignment'], {
      is: (assignedTo: number) => assignedTo === PromoCodeAssignment.PhoneNumber,
      then: yup.string().trim().required('Country calling code is required.'),
    }),
  memberId: yup
    .string()
    .trim()
    .when(['assignment'], {
      is: (assignedTo: number) => assignedTo === PromoCodeAssignment.Member,
      then: yup.string().trim().required('Member ID is required.'),
    }),
});

const getAssignmentTargetFromFormData = (data: GeneratePromoCodeForm) => {
  switch (data.assignment) {
    case PromoCodeAssignment.None:
      return undefined;
    case PromoCodeAssignment.Email:
      return data?.email;
    case PromoCodeAssignment.PhoneNumber:
      return data?.phoneNumber;
    case PromoCodeAssignment.Member:
      return data?.memberId;
    default:
      return undefined;
  }
};

const getPromoCodeAssignmentLabelForKey = (key: string) => {
  switch (key) {
    case 'None':
      return 'Do not assign ownership';
    case 'Email':
      return 'Assign to specific email';
    case 'PhoneNumber':
      return 'Assign to specific phone number';
    case 'Member':
      return 'Assign to specific member';
    default:
      return key;
  }
};

interface GeneratePromoCodeDialogProps {
  open: boolean;
  promoCodeDefinitionId?: number;
  testId: string;
  onClose: (shouldRefresh: boolean) => void;
}

export const GeneratePromoCodeDialog = ({
  open,
  promoCodeDefinitionId,
  testId,
  onClose,
}: GeneratePromoCodeDialogProps) => {
  const defaultValues = () => ({
    quantity: undefined,
    assignment: PromoCodeAssignment.None,
    email: '',
    phoneNumber: '',
    internationalCountryCallingCode: 'US',
    memberId: '',
  });

  const { control, handleSubmit, formState, watch, reset } = useForm<GeneratePromoCodeForm>({
    mode: 'onBlur',
    defaultValues: defaultValues(),
    resolver: yupResolver(validationSchema),
  });
  const { errors } = formState;
  const generateIsLoading = useIsMutating({ mutationKey: 'generatePromoCodeMutation' }) > 0;
  const { api } = useApiHelpers();
  const { addApiError, showMessage } = useToastMessage();
  const activeBrand = useSelector(selectActiveBrand);
  const brandKey = activeBrand?.key || '';

  const generatePromoCodeMutation = useMutation<GenerateCodesResponse, ApiError, GenerateCodesRequest, unknown>(
    (request) => api.promoCodeDefinition.generateCodes(request),
    {
      mutationKey: 'generatePromoCodeMutation',
      onSuccess: (value: GenerateCodesResponse) => {
        if (value) {
          showMessage('Promo Code generated successfully.', 'success');
          onClose(true);
          reset(defaultValues());
        }
      },
      onError: (error) => addApiError(error),
    },
  );

  const handleGenerateCodeSubmit = (data: GeneratePromoCodeForm) => {
    generatePromoCodeMutation.mutateAsync({
      promoCodeDefinitionId: promoCodeDefinitionId ?? 0,
      quantity: data.quantity,
      assignment: data.assignment as PromoCodeAssignment,
      assignmentTarget: getAssignmentTargetFromFormData(data) as GenerateCodesRequest['assignmentTarget'],
      brandKey,
    });
  };

  const assignedToOptions = Object.keys(PromoCodeAssignment)
    .filter((key) => isNaN(Number(key)))
    .map((key) => ({
      code: PromoCodeAssignment[key as any] as any as PromoCodeAssignment,
      label: getPromoCodeAssignmentLabelForKey(key),
    }));

  const onAssignmentChange = () => {
    const quantity = watch('quantity');
    const assignment = watch('assignment');
    reset({
      quantity,
      assignment,
      email: '',
      phoneNumber: '',
      internationalCountryCallingCode: 'US',
      memberId: '',
    });
  };

  const handleOnClose = () => {
    reset(defaultValues());
    onClose(false);
  };

  const assignment = watch('assignment');
  const internationalCountryCallingCode = watch('internationalCountryCallingCode');

  return (
    <Dialog open={open} onClose={handleOnClose} fullWidth={true} maxWidth="sm">
      {generateIsLoading && <Loading message={'Generating...'} />}
      <DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        Generate Promo Code
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleOnClose}
          aria-label="close"
          size="small"
          disableRipple
          data-testid={`${testId}CloseButton`}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleSubmit(handleGenerateCodeSubmit)} autoComplete="off">
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item container xs={12}>
              <Grid item xs={12} sm={6} md={3}>
                <ControlledTextField
                  control={control as unknown as Control<FieldValues, object>}
                  name="quantity"
                  fullWidth
                  label="How many?"
                  placeholder="How many?"
                  error={errors.quantity != null}
                  helperText={errors.quantity?.message}
                  isAutoFocused
                  testId={`${testId}Quantity`}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <ControlledSelect
                control={control as unknown as Control<FieldValues, object>}
                name="assignment"
                label="Assigned To"
                fullWidth
                size="small"
                error={errors.assignment != null}
                helperText={errors.assignment?.message}
                onChange={onAssignmentChange}
                testId={`${testId}Assignment`}
              >
                {assignedToOptions.map((opt: any) => (
                  <MenuItem key={opt.code} value={opt.code}>
                    {opt.label}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </Grid>
            {assignment === PromoCodeAssignment.Email && (
              <Grid item xs={12} md={6}>
                <ControlledTextField
                  control={control as unknown as Control<FieldValues, object>}
                  name="email"
                  fullWidth
                  label="Email"
                  placeholder="Email"
                  error={errors.email != null}
                  helperText={errors.email?.message}
                  testId={`${testId}Email`}
                />
              </Grid>
            )}
            {assignment === PromoCodeAssignment.PhoneNumber && (
              <>
                <Grid item xs={5} sm={4} md={2.5} sx={{ pl: 1 }}>
                  <ControlledInternationalCountryCallingCodesSelect
                    control={control as unknown as Control<FieldValues, object>}
                    name="internationalCountryCallingCode"
                    label="Country"
                    fullWidth
                    error={errors?.internationalCountryCallingCode?.message != null}
                    helperText={errors?.internationalCountryCallingCode?.message}
                    testId={`${testId}Country`}
                  />
                </Grid>
                <Grid item xs={7} sm={8} md={3.5}>
                  <ControlledPhoneNumberInput
                    control={control as unknown as Control<FieldValues, object>}
                    name="phoneNumber"
                    label="Phone Number"
                    fullWidth
                    error={errors?.phoneNumber?.message != null}
                    helperText={errors?.phoneNumber?.message}
                    country={internationalCountryCallingCode}
                    testId={testId}
                  />
                </Grid>
              </>
            )}
            {assignment === PromoCodeAssignment.Member && (
              <Grid item xs={12} md={6}>
                <ControlledTextField
                  control={control as unknown as Control<FieldValues, object>}
                  name="memberId"
                  fullWidth
                  label="Member ID"
                  placeholder="Member ID"
                  error={errors.memberId != null}
                  helperText={errors.memberId?.message}
                  testId={`${testId}MemberId`}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" size="small" type="submit" data-testid={`${testId}GenerateButton`}>
            Generate
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
