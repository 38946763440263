import CloseIcon from '@mui/icons-material/Close';
import { Box, Paper, useMediaQuery, useTheme } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { MemberResponse as Member } from '@one/api-models/lib/Admin/Members/Response/MemberResponse';

import { DataGrid } from 'common/dataGrid/DataGrid';
import { Loading } from 'common/Loading';
import { useCustomerSearch } from 'hooks/useCustomerSearch';
import { useFormat } from 'hooks/useFormat';
import { Customer } from 'models/customers/Customer';
import { CustomerStatus } from 'modules/customer/components/CustomerStatus';
import { AdditionalCustomerDetails } from 'modules/customer/components/search/AdditionalCustomerDetails';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from 'styled';

import { CustomerSearchForm } from './search/CustomerSearchForm';

interface PrincessCustomerSelectorProps {
  open: boolean;
  testId: string;
  handleClose: () => void;
  handleChange: (customer: Customer | null) => void;
}

export const PrincessCustomerSelector = ({
  open,
  testId,
  handleClose,
  handleChange,
}: PrincessCustomerSelectorProps) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const { formatDate } = useFormat();

  const {
    searchCriteriaChangedHandler,
    createCustomer,
    isLoading,
    fullCustomersList,
    hasResults,
    showAdditionalCustomerDetails,
    additionalDetailsRequest,
    handleCloseAddCustomer,
  } = useCustomerSearch();

  const onItemSelect = (member: Member) => {
    //[Princess Fast Track] - to be removed after proper implementation
    if (!member.memberKey) {
      createCustomer(member);
    } else {
      handleChange({
        ...member,
        accountStatus: member.status,
      });
      handleClose();
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: 'Name',
      sortable: true,
      flex: 1.5,
      filterable: false,
      valueGetter: (_, row) => `${row?.firstName || ''} ${row?.lastName || ''}`,
      renderCell: (params: any) => (
        <Box
          sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
          title={`${params?.row?.firstName || ''} ${params?.row?.lastName || ''}`}
        >
          <Typography variant="subtitle2">{`${params?.row?.firstName || ''} ${
            params?.row?.lastName || ''
          }`}</Typography>
        </Box>
      ),
    },
    {
      field: 'dateOfBirth',
      sortable: false,
      headerName: 'Date Of Birth',
      flex: 1,
      renderCell: (params: any) => {
        const formattedDate = formatDate(params?.row?.dateOfBirth, false, 'MMM dd, yyyy');
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }} title={formattedDate || ''}>
            {formattedDate}
          </Box>
        );
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 2.5,
      renderCell: (params: any) => (
        <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} title={params.row.email || ''}>
          {params?.row?.email?.toLowerCase()}
        </Box>
      ),
    },
    {
      field: 'mobilePhone',
      headerName: 'Mobile Phone',
      sortable: false,
      filterable: false,
      flex: 1.2,
      renderCell: (params: any) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }} title={params?.row?.mobilePhone || ''}>
          {params?.row?.mobilePhone}
        </Box>
      ),
    },
    {
      field: 'partnerLoyaltyId',
      headerName: 'PARTNER Loyalty ID',
      sortable: false,
      filterable: false,
      flex: 1.5,
      renderCell: (params: any) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }} title={params?.row?.partnerLoyaltyId || ''}>
          {params?.row?.partnerLoyaltyId}
        </Box>
      ),
    },
    {
      field: 'externalMemberKey',
      headerName: 'PARTNER Customer ID',
      width: 180,
      flex: 3,
      renderCell: (params: any) => (
        <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} title={params?.row?.externalMemberKey || ''}>
          {params?.row?.externalMemberKey}
        </Box>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: true,
      filterable: false,
      renderCell: (params: any) => <CustomerStatus status={params?.row?.status} />,
      flex: 1,
    },
  ];

  const customerCards = () => {
    return fullCustomersList?.map((customer: Member) => (
      <Paper
        elevation={8}
        sx={{ mt: 2, p: { xs: 1, md: 2 }, cursor: 'pointer' }}
        onClick={() => onItemSelect(customer)}
      >
        <Box display="flex" justifyContent="space-between" flexDirection="row">
          <Typography variant="body1">{customer.email?.toLocaleLowerCase()}</Typography>
          <CustomerStatus status={customer.status} />
        </Box>
        <Typography variant="body2">{`${customer.firstName || ''} ${customer.lastName || ''}`}</Typography>
        <Box display="flex" justifyContent="space-between" flexDirection={{ xs: 'column', md: 'row' }}>
          <Box mt={2} textAlign={{ xs: 'start', md: 'end' }}>
            <Typography variant="caption">Date of Birth</Typography>
            <Typography variant="body1">{formatDate(customer.dateOfBirth, false, 'MMM dd, yyyy')}</Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="caption">Phone Number</Typography>
            <Typography variant="body1">{customer.mobilePhone}</Typography>
          </Box>
        </Box>

        {customer.partnerLoyaltyId && (
          <Box mt={2}>
            <Typography variant="caption">PARTNER Loyalty ID</Typography>
            <Typography variant="body1">{customer.partnerLoyaltyId}</Typography>
          </Box>
        )}

        {customer.externalMemberKey && (
          <Box mt={2}>
            <Typography variant="caption">PARTNER Customer ID</Typography>
            <Typography variant="body1">{customer.externalMemberKey}</Typography>
          </Box>
        )}
      </Paper>
    ));
  };

  return (
    <Dialog
      open={open}
      scroll="paper"
      sx={{
        '.MuiDialog-paper': {
          width: '100%',
          maxWidth: '1300px',
        },
      }}
    >
      <DialogTitle>
        Find Customers
        <IconButton onClick={handleClose} size="small" disableRipple data-testid={`${testId}CloseButton`}>
          <CloseIcon sx={{ color: theme.customPalette.icons.light }} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ my: 3 }}>
        {isLoading && <Loading message={'Loading...'} />}
        <CustomerSearchForm
          onSearchCriteriaChange={searchCriteriaChangedHandler}
          allowBrandSpecific={true}
          testId={testId}
        />

        {hasResults && (
          <Box sx={{ pt: 6, height: '100%', width: '100%', overflow: { xs: 'scroll', lg: 'auto' } }}>
            <Typography variant="h5" sx={{ mb: 3 }}>
              Choose a customer
            </Typography>
            {mobileView ? (
              customerCards()
            ) : (
              <DataGrid
                rows={fullCustomersList.map((item: Member) => ({
                  ...item,
                  id: item.memberKey || item.externalMemberKey || item.partnerLoyaltyId,
                }))}
                columns={columns}
                onRowClick={(param: any) => onItemSelect(param?.row as Member)}
                hideFooter={true}
                sx={{
                  minWidth: { xs: 1300, lg: '100%' },
                  '& .MuiDataGrid-row': {
                    cursor: 'pointer',
                  },
                }}
              />
            )}
          </Box>
        )}

        {additionalDetailsRequest && (
          <AdditionalCustomerDetails
            isOpen={showAdditionalCustomerDetails}
            customer={additionalDetailsRequest}
            handleClose={handleCloseAddCustomer}
            testId={testId}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
