import { useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CompleteOrderRequest } from '@one/api-models/lib/Admin/ProgramSales/Purchase/CompleteOrderRequest';
import { CompleteOrderResponse } from '@one/api-models/lib/Admin/ProgramSales/Purchase/CompleteOrderResponse';
import { InitOrderRequest } from '@one/api-models/lib/Admin/ProgramSales/Purchase/InitOrderRequest';
import { InitOrderResponse } from '@one/api-models/lib/Admin/ProgramSales/Purchase/InitOrderResponse';
import { PaymentSource } from '@one/api-models/lib/Admin/ProgramSales/Purchase/PaymentSource';
import { PaymentToken } from '@one/api-models/lib/Admin/ProgramSales/Purchase/PaymentToken';
import { BillingDetails } from '@one/api-models/lib/BillingDetails';

import { selectActiveAgency, selectActiveBrand, selectActivePartner } from 'store/slices/applicationDataSlice';
import {
  resetSalesOrderDataSliceState,
  selectMetadata,
  selectPaymentPlan,
  selectSelectedCustomer,
  selectSelectedPrograms,
  setOrderKey,
  setPaymentError,
} from 'store/slices/salesOrderDataSlice';

import { ApiError } from 'apiAccess/api-client';
import { PAYMENT_AVS_MISMATCH_ERROR } from 'core/payment/constants/CybersourcePayment';
import { useApiHelpers } from 'hooks/useApiHelpers';
import { useToastMessage } from 'hooks/useToastMessage';

export const useProgramSale = () => {
  const dispatch = useDispatch();
  const { api } = useApiHelpers();
  const { apiErrorHandler } = useToastMessage();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const activePartner = useSelector(selectActivePartner);
  const activeBrand = useSelector(selectActiveBrand);
  const activeAgency = useSelector(selectActiveAgency);
  const selectedPrograms = useSelector(selectSelectedPrograms);
  const selectedCustomer = useSelector(selectSelectedCustomer);
  const paymentPlan = useSelector(selectPaymentPlan);
  const metadata = useSelector(selectMetadata);

  const initOrderMutation = useMutation<
    InitOrderResponse,
    ApiError,
    {
      paymentMethodId: number | undefined;
      billingDetails: BillingDetails;
    },
    unknown
  >(
    async ({ billingDetails, paymentMethodId }) => {
      return await api.programSales.initOrder({
        billingDetails,
        paymentPlan:
          paymentPlan && paymentPlan.paymentPlan
            ? {
                ...paymentPlan.paymentPlan,
              }
            : undefined,
        memberKey: selectedCustomer?.memberKey,
        programId: selectedPrograms[0]?.id,
        partnerKey: activePartner?.key,
        brandKey: activeBrand?.key,
        brandId: activeBrand ? parseInt(activeBrand?.key) : undefined,
        paymentMethodId: paymentMethodId,
        agencyKey: activeAgency?.key,
        metadata: metadata,
      } as InitOrderRequest);
    },
    {
      onSuccess: (value) => {
        if (value) {
          dispatch(setOrderKey(value.orderNumber));
        }
      },
      onError: (error) => {
        if (error.errors != null && error.errors.length > 0) {
          dispatch(setPaymentError(error.errors.map((e) => e.message).join('\n')));
        } else {
          dispatch(setPaymentError(error.message));
        }
        apiErrorHandler(error, undefined, undefined, PAYMENT_AVS_MISMATCH_ERROR);
        setIsLoading(false);
      },
    },
  );

  const processCreateOrder = async (
    paymentMethodId: number | undefined,
    billingDetails: BillingDetails | undefined,
  ) => {
    if (!billingDetails) throw new Error('Failed to get billingDetails');

    dispatch(setPaymentError(undefined));
    setIsLoading(true);

    const order = await initOrderMutation.mutateAsync({
      paymentMethodId,
      billingDetails,
    });
    performCompleteOrder(order.orderNumber, paymentMethodId, order.paymentTokens[0]);
  };

  const completeOrderMutation = useMutation<
    CompleteOrderResponse,
    ApiError,
    {
      orderKey: string;
      paymentMethodId: number | undefined;
      transactionId: number;
      paymentIntent: string;
      tokenReference?: string;
    },
    unknown
  >(
    async ({ orderKey, paymentMethodId, transactionId, paymentIntent, tokenReference }) => {
      const payment: PaymentSource = {
        amount:
          paymentPlan && paymentPlan?.totalDueToday
            ? paymentPlan?.totalDueToday
            : { amount: 0, currency: 'USD', isEstimated: false },
        transactionId: transactionId,
        paymentIntentReference: paymentIntent,
        paymentMethodReference: tokenReference,
        paymentMethodId: paymentMethodId,
        isPaymentMethodDefault: true,
      };
      return await api.programSales.completeOrder({
        memberKey: selectedCustomer?.memberKey,
        orderNumber: orderKey,
        partnerKey: activePartner?.key,
        brandKey: activeBrand?.key,
        paymentSources: [payment],
        agencyKey: activeAgency?.key,
      } as unknown as CompleteOrderRequest);
    },
    {
      onSuccess: (value) => {
        if (value) {
          navigate(`/sales/orders/${value.orderConfirmationNumber}`);
          dispatch(resetSalesOrderDataSliceState(false));
        }
        setIsLoading(false);
      },
      onError: (error) => {
        setIsLoading(false);
        if (error.errors != null && error.errors.length > 0) {
          dispatch(setPaymentError(error.errors.map((e) => e.message).join('\n')));
        } else {
          dispatch(setPaymentError(error.message));
        }
        apiErrorHandler(error);
      },
    },
  );

  const performCompleteOrder = (orderKey: string, paymentMethodId: number | undefined, paymentToken: PaymentToken) => {
    completeOrderMutation.mutate({
      orderKey,
      paymentMethodId,
      transactionId: paymentToken?.transactionId,
      paymentIntent: paymentToken?.secret,
      tokenReference: paymentToken?.paymentMethodGatewayReference,
    });
  };

  return {
    processCreateOrder,
    isLoading,
  };
};
