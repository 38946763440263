import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';

import { Box, useMediaQuery } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { styled, useTheme } from '@mui/material/styles';

import { selectApplicationDataIsLoading } from 'store/slices/applicationDataSlice';

import { Loading } from 'common';
import { AppMenuSidebar } from 'common/layout/AppMenuSidebar';
import { AppTopBar } from 'common/layout/AppTopBar';
import { TailorAppDock } from 'common/TailorAppDock';
import { LayoutConfig } from 'models/LayoutConfig';
import { AppRoute, routes } from 'router/Routes';

type Props = { config?: LayoutConfig; children: React.ReactNode };

const Content = styled('main')(({ theme }) => ({
  paddingBottom: theme.spacing(3),
  overflow: 'auto',
}));

export const Layout = ({ config, children }: Props) => {
  const location = useLocation();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [showDrawer, setShowDrawer] = useState(false);
  const [disablePartnerSelect, setDisablePartnerSelect] = useState(false);
  const testIdPrefix = 'TopBar';
  const isLoading = useSelector(selectApplicationDataIsLoading);
  const [currentRoute, setCurrentRoute] = useState<AppRoute | undefined>(undefined);

  useEffect(() => {
    for (const route of routes) {
      if (matchPath({ path: route.path }, location.pathname)) {
        setCurrentRoute(route);
        setDisablePartnerSelect(!!route.layoutConfig?.disablePartnerSelect);
        return;
      } else {
        if (route?.insideElements) {
          for (const insideRoute of route?.insideElements) {
            if (matchPath({ path: insideRoute.path }, location.pathname)) {
              setCurrentRoute(insideRoute);
              setDisablePartnerSelect(!!insideRoute.layoutConfig?.disablePartnerSelect);
              return;
            }
          }
        }
      }
    }
  }, [location.pathname]);

  return (
    <>
      <CssBaseline />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {currentRoute?.layoutConfig?.fullscreenLayout ? (
            <Box>{children}</Box>
          ) : (
            <Box
              display="flex"
              flexDirection="row"
              sx={{ background: 'linear-gradient(90deg, #FAFAFC 0%, #FAFAFC 50%, #fff 50%, #fff 100%)' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'row',
                  minHeight: '100vh',
                  margin: '0 auto',
                  justifyContent: 'flex-end',

                  pr: { xs: 0, lg: '40px' },
                  maxWidth: { xs: '100%', lg: '100vw' },
                  '&:after': {
                    content: '""',
                    display: 'block',
                    position: 'fixed',
                    left: '50%',
                    right: 0,
                    height: '1px',
                    background: '#E3E8EE',
                    top: '44px',
                    zIndex: 0,
                  },
                }}
              >
                <Box
                  sx={{
                    background: '#fff',
                    borderRight: '1px solid #ebeef1',
                    px: { xs: 0, lg: 3 },
                    flex: { xs: 0, lg: '20%' },
                    minWidth: { xs: 0, lg: '276px' },
                  }}
                >
                  <AppMenuSidebar
                    testId={testIdPrefix}
                    open={showDrawer}
                    handleDrawerClose={() => setShowDrawer(false)}
                    config={config}
                    disablePartnerSelect={disablePartnerSelect}
                  />
                </Box>

                <Box
                  sx={{
                    background: '#fff',
                    flex: { xs: '100%', lg: 'calc(min(80%, 100vw - 276px - 40px))' },
                    maxWidth: { xs: '100%', lg: 'calc(min(80%, 100vw - 276px - 40px))' },
                  }}
                >
                  <AppTopBar testId={testIdPrefix} handleDrawerOpen={() => setShowDrawer(true)} config={config} />
                  <Content>
                    <Box sx={{ m: 3 }}>{children}</Box>
                  </Content>
                </Box>
              </Box>
              {!smallScreen && <TailorAppDock testId={testIdPrefix} />}
            </Box>
          )}
        </>
      )}
    </>
  );
};
