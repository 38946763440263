import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import qs from 'qs';

import { Box, FormControlLabel, Switch } from '@mui/material';
import { GridFilterModel, GridPaginationModel, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import { TransactionListResponse } from '@one/api-models/lib/Admin/Transaction/TransactionListResponse';

import { selectActiveBrand, selectActivePartner } from 'store/slices/applicationDataSlice';
import {
  selectAllBrandsEnabled,
  selectCurrentPage,
  selectFiltersModel,
  setAllBrandsEnabled,
  setCurrentPage,
  setFiltersModel,
} from 'store/slices/salesTransactionsListDataSlice';

import { ApiError } from 'apiAccess/api-client';
import { Loading } from 'common';
import { PageHeader } from 'common/layout/components/PageHeader';
import { useApiHelpers } from 'hooks/useApiHelpers';
import { useFiltersToQueryParams } from 'hooks/useFiltersToQueryParams';
import { useFormat } from 'hooks/useFormat';
import { useToastMessage } from 'hooks/useToastMessage';
import { loadFiltersFromQueryParams } from 'utils/salesListFilters';

import { TransactionsDataGrid } from './components/TransactionsDataGrid';

const defaultFilters = {
  statusFilter: [],
  customerEmailFilter: undefined,
  transactionGatewayReferenceFilter: undefined,
  subscriptionGatewayReferenceFilter: undefined,
  arnFilter: undefined,
  orderReferenceFilter: undefined,
  transactionIdsFilter: [],
  startDate: undefined,
  endDate: undefined,
  customerKey: undefined,
  customerFirstName: undefined,
  customerLastName: undefined,
};

export type TransactionsFilters = {
  statusFilter: string[];
  customerEmailFilter: string | undefined;
  transactionGatewayReferenceFilter: string | undefined;
  subscriptionGatewayReferenceFilter: string | undefined;
  arnFilter: string | undefined;
  orderReferenceFilter: string | undefined;
  transactionIdsFilter: number[];
  startDate: Date | undefined;
  endDate: Date | undefined;
  customerKey: string | undefined;
  customerFirstName: string | undefined;
  customerLastName: string | undefined;
};

export const Transactions = () => {
  const defaultPageSize = 25;
  const dispatch = useDispatch();
  const { addApiError } = useToastMessage();
  const { api } = useApiHelpers();
  const { getEndDate, getStartDate } = useFormat();
  const [searchParams] = useSearchParams();

  const updatePage = (page: number) => dispatch(setCurrentPage(page));
  const updateAllBrandsEnabled = (enabled: boolean) => dispatch(setAllBrandsEnabled(enabled));
  const updateFiltersModel = (model: GridFilterModel) => dispatch(setFiltersModel(model));

  const filtersModel = useSelector(selectFiltersModel);

  const { saveFiltersToQs } = useFiltersToQueryParams();

  const activePartner = useSelector(selectActivePartner);
  const activeBrand = useSelector(selectActiveBrand);
  const allBrandsEnabled = useSelector(selectAllBrandsEnabled);
  const page = useSelector(selectCurrentPage);

  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [shouldResetPage, setShouldResetPage] = useState(false);

  // store every filter component interaction in order to update the component UI
  const [componentFilters, setComponentFilters] = useState<GridFilterModel>(filtersModel);
  const isUpdatingFromQs = useRef(false);
  const isUpdatingFromRedux = useRef(false);

  useEffect(() => {
    setComponentFilters(filtersModel);
  }, [filtersModel]);

  useEffect(() => {
    if (isUpdatingFromQs.current) {
      isUpdatingFromQs.current = false;
      return;
    }

    saveFiltersToQs(filtersModel, page, allBrandsEnabled);
    isUpdatingFromRedux.current = true; // Set flag to indicate update from Redux
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersModel, page, allBrandsEnabled]);

  useEffect(() => {
    if (isUpdatingFromRedux.current) {
      isUpdatingFromRedux.current = false;
      return;
    }

    const parsed = qs.parse(searchParams.toString(), { ignoreQueryPrefix: true });

    const showAllBrands = parsed.allBrands ? true : false;
    const currentPage = parsed.page ? parseInt(parsed.page as string, 10) : 0;
    const urlFilters = loadFiltersFromQueryParams(parsed);

    updateAllBrandsEnabled(showAllBrands);
    updatePage(currentPage);

    isUpdatingFromQs.current = true; // Set flag to indicate update from QS
    updateFiltersModel(urlFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    setIsInitialLoading(true);
  }, [activePartner, activeBrand]);

  const {
    data: transactions,
    isFetching,
    status,
  } = useQuery<TransactionListResponse, ApiError>(
    ['transactionsData', activeBrand, activePartner, page, pageSize, filtersModel, allBrandsEnabled],
    () =>
      api.transaction.loadTransactionList({
        ...getFilterPayload(
          getFilters(filtersModel, defaultFilters),
          allBrandsEnabled ? [] : [`${activePartner?.key}`],
          allBrandsEnabled ? [] : [`${activeBrand?.key}`],
          getStartDate,
          getEndDate,
        ),
        listingCriteria: {
          skip: page * pageSize,
          take: pageSize,
        },
      }),
    {
      enabled: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      keepPreviousData: true,
      onError: (error: ApiError) => addApiError(error),
      onSuccess: () => {
        setIsInitialLoading(false);
        setShouldResetPage(false);
      },
    },
  );

  const handlePaginationChanges = (data: GridPaginationModel) => {
    setPageSize(data.pageSize);
    updatePage(data.page);
  };

  const resetPage = () => {
    updatePage(0);
    setPageSize(defaultPageSize);
    setShouldResetPage(true);
  };

  useEffect(() => {
    // onSuccess from useQuery is not being called at the initial render
    if (status === 'success') {
      setIsInitialLoading(false);
    }
  }, [status]);

  const handleFilterModelChange = (filters: GridFilterModel) => {
    // Handle case when the filters array is empty
    if (!filters?.items?.length) {
      updateFiltersModel(filters);
      resetPage();
      return;
    }

    //Prevent API call when a new filter is added and the value is undefined and the amount is empty or the first name and last name are empty
    if (
      filters.items.every(
        (item) =>
          item?.value !== undefined &&
          item?.value.amount !== '' &&
          item?.value.firstName !== '' &&
          item?.value.lastName !== '',
      )
    ) {
      updateFiltersModel(filters);
      // Reset the page if it's not the first page
      if (page !== 0) {
        resetPage();
      }
      return;
    }

    setComponentFilters(filters);
  };

  return (
    <Box>
      <PageHeader title="Transactions" testId={'Transactions'} />
      {isInitialLoading && <Loading message={'Loading...'} />}
      <TransactionsDataGrid
        isLoading={isFetching}
        transactions={transactions?.transactions}
        allBrandsEnabled={allBrandsEnabled}
        shouldResetPage={shouldResetPage}
        handlePaginationChanges={handlePaginationChanges}
        handleFilterModelChange={handleFilterModelChange}
        page={page}
        pageSize={pageSize}
        filters={componentFilters}
        gridToolbar={
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 0.5 }}>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <GridToolbarFilterButton />
              <FormControlLabel
                control={
                  <Switch
                    checked={allBrandsEnabled}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      updateAllBrandsEnabled(event.target.checked);
                      setShouldResetPage(true);
                    }}
                    inputProps={{
                      // eslint-disable-next-line
                      //@ts-ignore
                      'data-testid': `TransactionsAllBrandsSwitchInput`,
                    }}
                  />
                }
                label="Show transactions for all brands"
              />
            </Box>
            <GridToolbarExport
              csvOptions={{
                fileName: `Transactions_${new Date().toISOString()}`,
                allColumns: true,
              }}
            />
          </Box>
        }
      />
    </Box>
  );
};

const getFilterPayload = (
  filters: TransactionsFilters,
  partnerKeys: string[],
  brandKeys: string[],
  getStartDate: (date?: Date) => Date | undefined,
  getEndDate: (date?: Date) => Date | undefined,
) => {
  const defaultStartDate = getStartDate(new Date('2010-01-01'));

  const payload: any = {
    partnerKeys: partnerKeys,
    brandKeys: brandKeys,
    createdStartDate: filters.startDate ? getStartDate(new Date(filters.startDate)) : defaultStartDate,
    createdEndDate: filters.endDate ? getEndDate(new Date(filters.endDate)) : undefined,
    statuses: filters.statusFilter || [],
    transactionIds: filters.transactionIdsFilter || [],
  };

  if (filters.customerEmailFilter) {
    payload['customerEmail'] = filters.customerEmailFilter;
  }
  if (filters.transactionGatewayReferenceFilter) {
    payload['transactionGatewayReference'] = filters.transactionGatewayReferenceFilter;
  }
  if (filters.subscriptionGatewayReferenceFilter) {
    payload['subscriptionGatewayReference'] = filters.subscriptionGatewayReferenceFilter;
  }
  if (filters.arnFilter) {
    payload['acquirerReferenceNumber'] = filters.arnFilter;
  }
  if (filters.orderReferenceFilter) {
    payload['orderNumber'] = filters.orderReferenceFilter;
  }
  if (filters.customerKey) {
    payload['customerKey'] = filters.customerKey;
  }
  if (filters.customerFirstName || filters.customerLastName) {
    payload['customerFirstName'] = filters.customerFirstName;
    payload['customerLastName'] = filters.customerLastName;
  }

  return payload;
};

const getFilters = (filtersModel: GridFilterModel, defaultOrderFilters: TransactionsFilters) => {
  const filters: TransactionsFilters = { ...defaultOrderFilters };

  filtersModel.items.forEach((filterItem) => {
    const { field: filterType, value: filterValue } = filterItem;

    if (!filterValue) {
      return;
    }

    switch (filterType) {
      case 'Status':
        filters.statusFilter = filterValue ? [filterValue] : [];
        break;

      case 'transactionID':
        const transactionIdsArray =
          filterValue
            ?.split(/[\s,]+/) // split by comma or space
            .map((id: string) => parseInt(id, 10))
            .filter((id: number) => !isNaN(id)) || [];
        filters.transactionIdsFilter = transactionIdsArray;
        break;

      case 'createdDateTime':
        filters.startDate = filterValue.start;
        filters.endDate = filterValue.end;
        break;

      case 'customerEmail':
        filters.customerEmailFilter = filterValue;
        break;

      case 'gatewayReference':
        filters.transactionGatewayReferenceFilter = filterValue;
        break;

      case 'subscriptionGatewayReference':
        filters.subscriptionGatewayReferenceFilter = filterValue;
        break;

      case 'arn':
        filters.arnFilter = filterValue;
        break;

      case 'orderReference':
        filters.orderReferenceFilter = filterValue;
        break;

      case 'customerKey':
        filters.customerKey = filterValue;
        break;

      case 'customer':
        if (
          filterValue.firstName &&
          filterValue.lastName &&
          (filterValue.firstName.length > 0 || filterValue.lastName.length > 0)
        ) {
          filters.customerFirstName = filterValue.firstName;
          filters.customerLastName = filterValue.lastName;
        }
        break;
    }
  });

  return filters;
};
