import { useMemo } from 'react';
import { Control, DeepRequired, FieldErrorsImpl, FieldValues } from 'react-hook-form';
import { debounce } from 'lodash';

import { Alert, Box, Grid, LinearProgress, Typography } from '@mui/material';
import { DepositPlan } from '@one/api-models/lib/Admin/PaymentPlan/DepositPlan';
import { InstallmentPlan } from '@one/api-models/lib/Admin/PaymentPlan/InstallmentPlan';
import { PaymentPlanBase } from '@one/api-models/lib/Admin/PaymentPlan/PaymentPlanBase';

import ControlledAutocomplete from 'common/inputs/defaultFields/ControlledAutocomplete';
import ControlledDatePicker from 'common/inputs/defaultFields/ControlledDatePicker';
import { SectionTitle } from 'common/SectionTitle';
import { useFormat } from 'hooks/useFormat';
import { UpdatePaymentPlanForm } from 'modules/paymentPlans/components/EditPaymentPlanDialog';

interface Props {
  paymentPlanDetails: PaymentPlanBase | DepositPlan | InstallmentPlan | undefined;
  previewData: PaymentPlanBase | DepositPlan | InstallmentPlan | undefined;
  handleCalculatePaymentPlan: () => void;
  control: Control<UpdatePaymentPlanForm, object>;
  errors: FieldErrorsImpl<DeepRequired<UpdatePaymentPlanForm>>;
  testId: string;
  isInstallmentPlan?: boolean;
  isPaymentPlanEditable?: boolean;
  isLoading?: boolean;
}

export const PaymentPlanScheduleSection = (props: Props) => {
  const {
    isLoading,
    paymentPlanDetails,
    previewData,
    handleCalculatePaymentPlan,
    errors,
    testId,
    control,
    isInstallmentPlan,
    isPaymentPlanEditable,
  } = props;
  const { formatDate, formatRecurringInterval, formatRecurringInvoice, formatCurrency } = useFormat();

  const initialRecurringInterval = useMemo(() => {
    if (paymentPlanDetails) {
      return {
        recurringIntervalType:
          'recurringInterval' in paymentPlanDetails ? paymentPlanDetails?.recurringInterval : undefined,
        recurringIntervalCount: 'intervalCount' in paymentPlanDetails ? paymentPlanDetails?.intervalCount : undefined,
        recurringMaxIntervalCount:
          'maxAllowedIntervalCount' in paymentPlanDetails ? paymentPlanDetails?.maxAllowedIntervalCount : undefined,
        minAllowedIntervalCount:
          'minAllowedIntervalCount' in paymentPlanDetails ? paymentPlanDetails?.minAllowedIntervalCount : undefined,
      };
    }
  }, [paymentPlanDetails]);

  const getTermOptions = () => {
    const termOptions = [];
    if (initialRecurringInterval?.recurringMaxIntervalCount) {
      const minAllowedIntervalCount = initialRecurringInterval.minAllowedIntervalCount || 1;
      for (let i = minAllowedIntervalCount; i <= initialRecurringInterval?.recurringMaxIntervalCount; i++) {
        termOptions.push({
          code: `${i}`,
          label: `${i}`,
        });
      }
    }

    return termOptions;
  };

  const debouncedHandleScheduleChange = debounce(() => {
    handleCalculatePaymentPlan();
  }, 500);

  const renderScheduleSection = () => {
    let renderItem = null;
    const recurringAmount = !!previewData
      ? previewData?.nextPaymentAmount?.amount
      : paymentPlanDetails?.nextPaymentAmount?.amount;

    if (isInstallmentPlan) {
      renderItem = initialRecurringInterval?.recurringIntervalCount ? (
        <Grid container spacing={2} rowSpacing={1}>
          <Grid item xs={3.5} md={2} lg={1.5}>
            <ControlledAutocomplete
              name="intervalCount"
              placeholder={initialRecurringInterval?.recurringIntervalCount.toString() || 'Select'}
              options={getTermOptions() || []}
              error={errors.intervalCount != null}
              helperText={errors.intervalCount?.message}
              control={control as unknown as Control<FieldValues, object>}
              onChange={() => {
                debouncedHandleScheduleChange();
              }}
              disabled={!isInstallmentPlan || !isPaymentPlanEditable}
              disableDefaultTextFieldChange
              testId={`${testId}IntervalCount`}
            />
          </Grid>
          <Grid item>
            <Box display="flex" height="100%" alignItems="center">
              <Typography variant="subtitle1">
                {`${formatRecurringInterval(
                  initialRecurringInterval?.recurringMaxIntervalCount,
                  initialRecurringInterval?.recurringIntervalType,
                )} ${
                  (previewData && previewData?.nextPaymentAmount) ||
                  (paymentPlanDetails && paymentPlanDetails?.nextPaymentAmount)
                    ? '@'
                    : ''
                }`}
              </Typography>
              <Typography variant="subtitle1" ml={1}>
                {isLoading ? (
                  <LinearProgress sx={{ width: '100px' }} color="primary" />
                ) : (
                  ((previewData && previewData?.nextPaymentAmount) ||
                    (paymentPlanDetails && paymentPlanDetails?.nextPaymentAmount)) &&
                  `${formatRecurringInvoice(
                    formatCurrency(recurringAmount, paymentPlanDetails?.nextPaymentAmount?.currency, 2),
                    initialRecurringInterval?.recurringIntervalType,
                  )}`
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Alert severity="info">The schedule can't be updated as there is only one invoice remaining</Alert>
      );
    } else {
      renderItem = (
        <Grid container spacing={2} rowSpacing={1}>
          <Grid item>
            <Box display="flex" height="100%" alignItems="center">
              <Typography color="#6A7383" sx={{ fontSize: '14px', fontWeight: '400' }}>
                Change end term from{' '}
                {paymentPlanDetails && formatDate(new Date(paymentPlanDetails.paymentPlanEndDate), true, 'MMM dd')} to
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4} md={2.3}>
            <ControlledDatePicker
              control={control as unknown as Control<FieldValues, object>}
              name="endTerm"
              label=""
              placeholder="End Term"
              openTo="day"
              error={errors?.endTerm?.message != null}
              helperText={errors?.endTerm?.message}
              fullWidth
              onChange={() => {
                debouncedHandleScheduleChange();
              }}
              disabled={!isInstallmentPlan || !isPaymentPlanEditable}
              testId={`${testId}EndTerm`}
            />
          </Grid>
        </Grid>
      );
    }

    return renderItem;
  };

  return (
    <Box sx={{ mb: 6 }}>
      <SectionTitle title="Payments Schedule" variant="h4" />

      {renderScheduleSection()}
    </Box>
  );
};
