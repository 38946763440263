import { Backdrop, CircularProgress, Grid, Typography } from '@mui/material';

export const AddPaymentMethodLoading = ({
  isLoadingConfiguration,
  isLoadingCreatePaymentMethod,
}: {
  isLoadingConfiguration: boolean;
  isLoadingCreatePaymentMethod: boolean;
}) => (
  <Backdrop
    open={isLoadingConfiguration || isLoadingCreatePaymentMethod}
    sx={{
      color: '#fff',
      zIndex: (theme) => theme.zIndex.drawer + 1,
      backgroundColor: 'rgba(245,245,245,0.8)',
      height: '100%',
    }}
  >
    <Grid spacing={1} container direction="column" justifyContent="center" alignItems="center">
      <Grid item>
        <CircularProgress color="primary" data-testid="LoadingSpinner" />
      </Grid>
      {isLoadingConfiguration && (
        <>
          <Grid item>
            <Typography variant="h6" sx={{ color: '#c62828' }}>
              Session Expired
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption" sx={{ color: 'rgba(0,0,0,0.84)' }}>
              Restarting payment...
            </Typography>
          </Grid>
        </>
      )}
      {isLoadingCreatePaymentMethod && (
        <Grid item>
          <Typography variant="h6" sx={{ color: 'rgba(0,0,0,0.84)' }}>
            Please wait...
          </Typography>
        </Grid>
      )}
    </Grid>
  </Backdrop>
);
