import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { selectApplicationDataIsLoading } from 'store/slices/applicationDataSlice';

import { Loading } from 'common';
import { useAppHelpers } from 'hooks/useAppHelpers';
import { useAuthWrapperHelpers } from 'hooks/useAuthWrapperHelpers';

export const AppNoPermissions = () => {
  const { logout } = useAuthWrapperHelpers();
  const { hasPermission } = useAppHelpers();
  const navigate = useNavigate();
  const testIdPrefix = 'AppNoPermissions';

  const isLoading = useSelector(selectApplicationDataIsLoading);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        minHeight: '100vh',
        alignItems: 'center',
        flexDirection: 'column',
        p: 1,
      }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Typography variant="h4" align="center">
            Please contact your administrator and <br />
            ask if your permissions are set for this page.
          </Typography>
          {hasPermission(['Dashboard:View', 'Agency.Dashboard:View']) ? (
            <Button
              variant="contained"
              sx={{ mt: 3 }}
              onClick={() => navigate('/')}
              data-testid={`${testIdPrefix}GoToDashboardButton`}
            >
              Go to dashboard
            </Button>
          ) : (
            <Button variant="contained" sx={{ mt: 3 }} onClick={logout} data-testid={`${testIdPrefix}LogOutButton`}>
              Log out
            </Button>
          )}
        </>
      )}
    </Box>
  );
};
